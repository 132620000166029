import {BACKEND} from '../../../../consts/backend';

const FollowUpExpoGet301CarrierLeg = async (po) => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const resp = await fetch(
    `${BACKEND}/expo-material/carriers-leg`,
    requestOptions
  );
  const sku = await resp.json();
  return sku.data;
};

export default FollowUpExpoGet301CarrierLeg;
