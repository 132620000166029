import {useCallback, useEffect, useReducer} from 'react';
import Select from 'react-select';

const CustomSelect = ({
  optionsSelect,
  onChange,
  defaultValueSelect,
  id,
  name = '',
  placeholder,
  placement = 'bottom',
  readonly = false,
  index = -1,
  state = '',
  multi = false,
  referencia = null,
}) => {
  const equalvalue = (element) => {
    if (element.value !== null) {
      return String(element.value) === String(defaultValueSelect);
    } else return false;
  };
  const equallabel = (element) => {
    if (element.label !== null) {
      return String(element.label) === String(defaultValueSelect);
    } else return false;
  };
  const getdefaultValue = () => {
    if (multi) {
      if (Array.isArray(defaultValueSelect)) {
        let aux = optionsSelect.filter((element) =>
          defaultValueSelect.includes(element.value)
        );
        return aux;
      } else {
        return null;
      }
    } else {
      if (
        defaultValueSelect !== null &&
        typeof defaultValueSelect !== 'undefined'
      ) {
        let index = optionsSelect.findIndex(equalvalue);

        if (index !== -1) {
          return optionsSelect[index] || null;
        } else {
          index = optionsSelect.findIndex(equallabel);
          return index !== -1 ? optionsSelect[index] : null;
        }
      } else {
        return null;
      }
    }
  };

  const customFilter = useCallback((candidate, input) => {
    if (
      String(candidate.label)
        .toLowerCase()
        .includes(String(input).toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  return (
    <Select
      ref={(ref) => {
        if (referencia !== null) {
          referencia(ref);
        }
      }}
      defaultValue={getdefaultValue}
      options={optionsSelect}
      onChange={(e) => onChange(e, id, index, name)}
      placeholder={placeholder}
      menuPlacement={placement}
      isDisabled={readonly}
      filterOption={customFilter}
      isMulti={multi}
    />
  );
};

export default CustomSelect;
