import {apiKey} from '../../listgsa/helpers/keys.helper';
import {BACKEND} from '../../consts/backend';

const CreateOrder = async (newOrder) => {
  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: apiKey,
    },
    body: JSON.stringify(newOrder),
  };

  const resp = await fetch(
    `${BACKEND}/impo/add-order`,
    requestOptions
  );

  const sku = await resp.json();

  return sku.data;
};

export default CreateOrder;
