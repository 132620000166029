import {Fragment, useState} from 'react';
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import {Block, Icon} from '../../components/Component';
import CustomSelect from '../../Exportation/followUp/Components/customselect';

export const CreateUser = ({roles, enterprise}) => {
  const [newUser, setNewUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confEmail: '',
    passwords: '',
    confPasswords: '',
    rol: '',
    enterprise: [],
  });
  const [refRol, setRefRol] = useState({});
  const [refEnterprise, setRefEnterprise] = useState({});

  const onChange = (e, id = '') => {
    if (id) {
    } else {
      setNewUser((element) => ({
        ...element,
        [e.target.id]: e.target.value,
      }));
    }
  };

  const reset = () => {
    clearValue();
    setNewUser({
      firstName: '',
      lastName: '',
      email: '',
      confEmail: '',
      passwords: '',
      confPasswords: '',
      rol: '',
      enterprise: [],
    });
  };

  const onSubmit = () => {};

  const clearValue = () => {
    refRol.clearValue();
    refEnterprise.clearValue();
  };

  return (
    <Fragment>
      <Container>
        <Block>
          <div className="nk-modal-head mb-3">
            <h5>Create User</h5>
          </div>
          <Row className="gy-3">
            <Col md="12">
              <div className={`accordion-head`}>
                <div className="accordion-item">
                  <div className="accordion-inner">
                    <Row className="mb-auto p-2">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            First Name
                          </Label>
                          <Input
                            id="firstName"
                            type="text"
                            onChange={onChange}
                            value={newUser.firstName}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            Last Name
                          </Label>
                          <Input
                            id="lastName"
                            type="text"
                            onChange={onChange}
                            value={newUser.lastName}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            E-mail
                          </Label>
                          <Input
                            id="email"
                            type="text"
                            onChange={onChange}
                            value={newUser.email}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            Confirm E-mail
                          </Label>
                          <Input
                            id="confEmail"
                            type="text"
                            onChange={onChange}
                            value={newUser.confEmail}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            Password
                          </Label>
                          <Input
                            id="passwords"
                            type="password"
                            onChange={onChange}
                            value={newUser.passwords}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            Confirm Password
                          </Label>
                          <Input
                            id="confPasswords"
                            type="password"
                            onChange={onChange}
                            value={newUser.confPasswords}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            Rol
                          </Label>
                          <CustomSelect
                            optionsSelect={roles}
                            id={'rol'}
                            referencia={setRefRol}
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            Enterprise
                          </Label>
                          <CustomSelect
                            optionsSelect={enterprise}
                            id={'enterprise'}
                            referencia={setRefEnterprise}
                            onChange={onChange}
                            multi={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col md="6">
                        <FormGroup>
                          <Button onClick={() => onSubmit()}>
                            <Icon name="plus" />
                            Create User
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Button
                            color="danger"
                            onClick={() => reset()}
                          >
                            <Icon name="trash" />
                            Clear
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Block>
      </Container>
    </Fragment>
  );
};
