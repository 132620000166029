import React, {useEffect, useState} from 'react';
import {Col, FormGroup, Modal, Row} from 'react-bootstrap';
import {Input, Label} from 'reactstrap';

import UserProvider from '../helpers/userProvider';
export const UserEdit = ({user}) => {
  const [rol, setRol] = useState();
  useEffect(() => {
    new UserProvider().getRol(user.id).then((result) => {
      setRol(result);
    });
  }, []);

  const roles = {
    label: '',
  };

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit User {user.nickname}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-2">
          <div className="tab-content">
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    First Name
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="nickname"
                    defaultValue={user.nickname}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Last Name
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="nickname"
                    defaultValue={user.nickname}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    E-mail
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="nickname"
                    defaultValue={user.email}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Confirmar E-mail
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="nickname"
                    defaultValue={user.email}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Password
                  </Label>
                  <Input
                    className="form-control"
                    type="password"
                    id="nickname"
                    defaultValue={user.email}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Confirm Password
                  </Label>
                  <Input
                    className="form-control"
                    type="password"
                    id="nickname"
                    defaultValue={user.email}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    E-mail
                  </Label>
                  <Input
                    className="form-control"
                    type="password"
                    id="nickname"
                    defaultValue={user.email}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Confirm E-mail
                  </Label>
                  <Input
                    className="form-control"
                    type="password"
                    id="nickname"
                    defaultValue={user.email}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
    </React.Fragment>
  );
};
