import React from 'react';
import {UsersBoard} from './components/UsersBoard';

export const UserManagement = () => {
  return (
    <>
      <React.Fragment>
        <UsersBoard />
      </React.Fragment>
    </>
  );
};
