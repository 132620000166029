import {Fragment, useState} from 'react';
import {
  FormGroup,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import {
  dollarUSA,
  tableNumberFormat,
} from '../../../../helpers/FoundValue';
import CustomSelect from '../../customselect';

export const Products = ({
  productEvent,
  setProductEvent,
  gmid,
  countries,
  currencies,
}) => {
  const addTableRows = () => {
    let aux = productEvent.materials;
    const rowsInputEvent = {
      order_id: '',

      material_id: '',

      quantity_uom: 0,

      code: 0,

      name: 0,

      packaging_form_code: '',

      ncm_code: 0,

      weight_net_uom: 0,

      weight_gross_uom: 0,

      materials_pallet: 0,

      unit_price: 0,

      pricing_information_monetary_amount: 0,

      materials_freight: 0,

      materials_insurance: 0,

      materials_total_price: 0,

      material_origin: '',

      shipment_id: '',

      exchange_rate_currency_code:
        productEvent.materials.length === 0
          ? '1'
          : productEvent.materials[0]?.exchange_rate_currency_code,

      containers_quantity:
        productEvent.materials.length === 0
          ? ''
          : productEvent.materials[0]?.containers_quantity,
    };

    aux.push(rowsInputEvent);

    setProductEvent((element) => ({
      ...element,
      materials: aux,
    }));
  };
  const onChange = (e, index) => {
    const aux = [...productEvent.materials];
    let sum = 0;
    let sumTotal = 0;
    let sumQuantity = 0;
    let val =
      e.target.value != ''
        ? String(e.target.value).replace(',', '.')
        : '0';
    aux[index][e.target.name] = val;

    if (e.target.name == 'quantity_uom') {
      aux[index]['pricing_information_monetary_amount'] =
        aux[index]['quantity_uom'] * aux[index]['unit_price'];
      sumTotal = aux.reduce(
        (acc, el) =>
          acc +
          parseFloat(
            parseFloat(el.pricing_information_monetary_amount) +
              parseFloat(el.materials_freight) +
              parseFloat(el.materials_insurance)
          ),
        0
      );
      sumQuantity = aux.reduce(
        (acc, el) => acc + parseFloat(el.quantity_uom || 0),
        0
      );
      sum = aux.reduce(
        (acc, el) =>
          acc +
          parseFloat(el.pricing_information_monetary_amount || 0),
        0
      );
      setProductEvent((element) => ({
        ...element,
        totalsuma: {
          ...element.totalsuma,
          totalFobFca: sum,
          lastTotal: sumTotal,
          quantity_total: sumQuantity,
        },
      }));
    }
    if (e.target.name == 'weight_gross_uom') {
      sum = aux.reduce(
        (acc, el) => acc + parseFloat(el.weight_gross_uom || 0),
        0
      );
      setProductEvent((element) => ({
        ...element,
        totalsuma: {
          ...element.totalsuma,
          totalWeightGross: sum,
        },
      }));
    }
    if (e.target.name == 'weight_net_uom') {
      sum = aux.reduce(
        (acc, el) => acc + parseFloat(el.weight_net_uom || 0),
        0
      );
      setProductEvent((element) => ({
        ...element,
        totalsuma: {
          ...element.totalsuma,
          totalWeightNet: sum,
        },
      }));
    }
    if (e.target.name == 'materials_pallet') {
      sum = aux.reduce(
        (acc, el) => acc + parseFloat(el.materials_pallet || 0),
        0
      );
      setProductEvent((element) => ({
        ...element,
        totalsuma: {
          ...element.totalsuma,
          pallets: sum,
        },
      }));
    }
    if (e.target.name == 'unit_price') {
      val = parseFloat(val.replace('$', ''));
      aux[index][e.target.name] = val || 0;
      aux[index]['pricing_information_monetary_amount'] =
        aux[index]['quantity_uom'] * aux[index]['unit_price'];
      sumTotal = aux.reduce(
        (acc, el) =>
          acc +
          parseFloat(
            parseFloat(el.pricing_information_monetary_amount) +
              parseFloat(el.materials_freight) +
              parseFloat(el.materials_insurance)
          ),
        0
      );
      sum = aux.reduce(
        (acc, el) =>
          acc +
          parseFloat(el.pricing_information_monetary_amount || 0),
        0
      );
      setProductEvent((element) => ({
        ...element,
        totalsuma: {
          ...element.totalsuma,
          totalFobFca: sum,
          lastTotal: sumTotal,
        },
      }));
    }
    if (e.target.name == 'pricing_information_monetary_amount') {
      val = parseFloat(val.replace('$', ''));
      aux[index][e.target.name] = val;
      sumTotal = aux.reduce(
        (acc, el) =>
          acc +
          parseFloat(
            parseFloat(el.pricing_information_monetary_amount) +
              parseFloat(el.materials_freight) +
              parseFloat(el.materials_insurance)
          ),
        0
      );
      sum = aux.reduce(
        (acc, el) =>
          acc +
          parseFloat(el.pricing_information_monetary_amount || 0),
        0
      );
      setProductEvent((element) => ({
        ...element,
        totalsuma: {
          ...element.totalsuma,
          totalFobFca: sum,
          lastTotal: sumTotal,
        },
      }));
    }

    if (e.target.name == 'materials_freight') {
      val = parseFloat(val.replace('$', ''));
      aux[index][e.target.name] = val;
      sum = aux.reduce(
        (acc, el) => acc + parseFloat(el.materials_freight || 0),
        0
      );
      sumTotal = aux.reduce(
        (acc, el) =>
          acc +
          parseFloat(el.pricing_information_monetary_amount || 0) +
          parseFloat(el.materials_freight || 0) +
          parseFloat(el.materials_insurance || 0),
        0
      );

      setProductEvent((element) => ({
        ...element,
        totalsuma: {
          ...element.totalsuma,
          freight: sum,
          lastTotal: sumTotal,
        },
      }));
    }
    if (e.target.name == 'materials_insurance') {
      val = parseFloat(val.replace('$', ''));
      aux[index][e.target.name] = val;
      sum = aux.reduce(
        (acc, el) => acc + parseFloat(el.materials_insurance || 0),
        0
      );
      sumTotal = aux.reduce(
        (acc, el) =>
          acc +
          parseFloat(el.pricing_information_monetary_amount || 0) +
          parseFloat(el.materials_freight || 0) +
          parseFloat(el.materials_insurance || 0),
        0
      );
      setProductEvent((element) => ({
        ...element,
        totalsuma: {
          ...element.totalsuma,
          insurance: sum,
          lastTotal: sumTotal,
        },
      }));
    } else {
    }
    let check = materialPdf(aux);

    setProductEvent((element) => ({
      ...element,
      materials: aux,
    }));
  };

  const materialPdf = (materialIterar) => {
    let tablepdf = [
      [
        {text: 'Item#', style: 'header_table'},
        {text: 'Goods Description', style: 'header_table'},
        {text: 'Quantity', style: 'header_table'},
        {text: 'Net Weight', style: 'header_table'},
        {text: 'Gross Weight', style: 'header_table'},
      ],
    ];
    let quantity = 0;
    let net = 0;
    let gross = 0;

    materialIterar.forEach((element, index) => {
      quantity +=
        element.material_quantity !== null
          ? parseInt(element.quantity_uom)
          : 0;
      net +=
        element.material_weight_net_uom !== null
          ? parseInt(element.weight_net_uom)
          : 0;
      gross +=
        element.material_weight_gross_uom !== null
          ? parseInt(element.weight_gross_uom)
          : 0;
      tablepdf.push([
        {text: index + 1, style: 'tabletext'},
        {text: element.name, style: 'tabletext'},
        {text: element.quantity_uom, style: 'tabletext'},
        {
          text: element.weight_net_uom,
          style: 'tabletext',
        },
        {
          text: element.weight_gross_uom,
          style: 'tabletext',
        },
      ]);

      if (index === materialIterar.length - 1) {
        tablepdf.push([
          {text: 'Total', colSpan: 2, style: 'header_table'},
          {text: '', style: 'header_table'},
          {text: quantity, style: 'tabletext'},
          {text: net, style: 'tabletext'},
          {text: gross, style: 'tabletext'},
        ]);
      }
    });

    setProductEvent((element) => ({
      ...element,
      tablePacking: tablepdf,
      packingData: {
        ...element.packingData,
        pallet: element.totalsuma.pallets,
      },
    }));
  };
  const deleteTableRows = () => {};
  const onblurinput = (e, id) => {
    if (!document.getElementById(id).value) {
      document.getElementById(id).value = 0;
    }
  };
  const onChangeSelect = (e, id, index = -1) => {
    if (index === -1) {
      let aux = productEvent.materials.map((element) => {
        if (id === 'containers_quantity') {
          return {
            ...element,
            [id]: parseInt(e.value),
          };
        } else {
          return {
            ...element,
            [id]: e.value,
          };
        }
      });
      setProductEvent((element) => ({
        ...element,
        materials: aux,
      }));
    } else {
      let aux = [...productEvent.materials];
      aux[index][id] = e.value;
      setProductEvent((element) => ({
        ...element,
        materials: aux,
      }));
    }
  };
  const totalWeightGross = () => {};

  return (
    <Fragment>
      <Container fluid>
        <div className="nk-modal-head mb-3">
          <h5>Products</h5>
        </div>
        <hr />
        <Row className="p-2">
          <Col
            lg="12"
            className="pb-3"
            style={{
              height: '40rem',
              overflowX: 'auto',
            }}
          >
            <Table style={{width: '100%'}}>
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '6rem'}}
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '8rem'}}
                  >
                    GMID
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '13rem'}}
                  >
                    DESCRIPTION
                  </th>
                  <th scope="col" className="text-nowrap">
                    Packinging
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '8rem'}}
                  >
                    Origin
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '9rem'}}
                  >
                    NCM
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '8rem'}}
                  >
                    GROSS WEIGHT
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '8rem'}}
                  >
                    NET WEIGHT
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '5rem'}}
                  >
                    PALLET
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '8rem'}}
                  >
                    UNIT NET PRICE
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '8rem'}}
                  >
                    TOTAL NET PRICE
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '8rem'}}
                  >
                    FREIGHT
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '8rem'}}
                  >
                    INSURANCE
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '8rem'}}
                  >
                    TOTAL
                  </th>
                  <th
                    scope="col"
                    className="text-nowrap"
                    style={{width: '7rem'}}
                  >
                    <button
                      className="btn btn-outline-success"
                      onClick={addTableRows}
                    >
                      +
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {productEvent.materials?.map((item, index) => (
                  <tr key={index}>
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        name="quantity_uom"
                        id={'quantity_uom' + 1}
                        onChange={(e) => onChange(e, index)}
                        defaultValue={
                          parseInt(item?.quantity_uom) || ''
                        }
                        onBlur={(e) =>
                          onblurinput(
                            'materials_freight',
                            e.target.id
                          )
                        }
                      ></Input>
                    </td>
                    <td scope="col" className="text-nowrap">
                      <CustomSelect
                        id={'code'}
                        optionsSelect={gmid}
                        defaultValueSelect={item?.code}
                        index={index}
                        placeholder={'Select Code'}
                        onChange={onChangeSelect}
                      />
                    </td>
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        id={'name' + index}
                        name="name"
                        onChange={(e) => onChange(e, index)}
                        defaultValue={item?.name || ''}
                      ></Input>
                    </td>
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        id={'packaging_form_code' + index}
                        name="packaging_form_code"
                        onChange={(e) => onChange(e, index)}
                        defaultValue={item?.packaging_form_code || ''}
                      ></Input>
                    </td>
                    <td scope="col" className="text-nowrap">
                      <CustomSelect
                        id={'material_origin'}
                        optionsSelect={countries}
                        defaultValueSelect={item.material_origin}
                        index={index}
                        placeholder={'Select Country Origin'}
                        onChange={onChangeSelect}
                      />
                    </td>
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        id={'ncm_code' + index}
                        name="ncm_code"
                        onChange={(e) => onChange(e, index)}
                        defaultValue={item?.ncm_code || ''}
                      ></Input>
                    </td>
                    {/*  */}
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        id={'weight_gross_uom' + index}
                        name="weight_gross_uom"
                        onChange={(e) => onChange(e, index)}
                        defaultValue={tableNumberFormat(
                          parseFloat(item?.weight_gross_uom || 0)
                        )}
                        onBlur={(e) =>
                          onblurinput(
                            'materials_freight',
                            e.target.id
                          )
                        }
                      ></Input>
                    </td>
                    {/*  */}
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        id={'weight_net_uom' + index}
                        name="weight_net_uom"
                        onChange={(e) => onChange(e, index)}
                        defaultValue={tableNumberFormat(
                          parseFloat(item?.weight_net_uom || 0)
                        )}
                        onBlur={(e) =>
                          onblurinput(
                            'materials_freight',
                            e.target.id
                          )
                        }
                      ></Input>
                    </td>
                    {/*  */}
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        id={'materials_pallet' + index}
                        name="materials_pallet"
                        onChange={(e) => onChange(e, index)}
                        defaultValue={item?.materials_pallet || 0}
                        onBlur={(e) =>
                          onblurinput(
                            'materials_freight',
                            e.target.id
                          )
                        }
                      ></Input>
                    </td>
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        id={'unit_price' + index}
                        name="unit_price"
                        onChange={(e) => onChange(e, index)}
                        defaultValue={
                          dollarUSA(
                            tableNumberFormat(
                              parseFloat(item?.unit_price),
                              'tnt'
                            )
                          ) || 0
                        }
                        onBlur={(e) =>
                          onblurinput(
                            'materials_freight',
                            e.target.id
                          )
                        }
                      ></Input>
                    </td>
                    {/*  */}
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        id={
                          'pricing_information_monetary_amount' +
                          index
                        }
                        name="pricing_information_monetary_amount"
                        onChange={(e) => onChange(e, index)}
                        value={dollarUSA(
                          tableNumberFormat(
                            parseFloat(
                              item?.pricing_information_monetary_amount
                            ) || 0
                          )
                        )}
                        disabled
                      ></Input>
                    </td>
                    {/*  */}
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        style={{float: 'left'}}
                        type="text"
                        name="materials_freight"
                        id={'materials_freight' + index}
                        onChange={(e) => onChange(e, index, item)}
                        onBlur={(e) =>
                          onblurinput(
                            'materials_freight',
                            e.target.id
                          )
                        }
                        defaultValue={dollarUSA(
                          tableNumberFormat(
                            parseFloat(item?.materials_freight)
                          ) || 0
                        )}
                      ></Input>
                    </td>
                    {/*  */}
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        type="text"
                        id={'materials_insurance' + index}
                        name="materials_insurance"
                        onChange={(e) => onChange(e, index)}
                        defaultValue={dollarUSA(
                          tableNumberFormat(
                            parseFloat(item?.materials_insurance) || 0
                          )
                        )}
                        onBlur={(e) =>
                          onblurinput(
                            'materials_freight',
                            e.target.id
                          )
                        }
                      ></Input>
                    </td>
                    {/*  */}
                    <td scope="col" className="text-nowrap">
                      <Input
                        className="text-nowrap"
                        readOnly
                        type="text"
                        id={'total' + index}
                        name="total"
                        value={dollarUSA(
                          tableNumberFormat(
                            parseFloat(
                              item?.pricing_information_monetary_amount
                            ) +
                              parseFloat(item?.materials_freight) +
                              parseFloat(item?.materials_insurance)
                          )
                        )}
                      ></Input>
                    </td>
                    <td scope="col" className="text-nowrap">
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => deleteTableRows(index)}
                      >
                        x
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6}></td>
                  <td scope="col" className="text-nowrap">
                    <h6 style={{fontSize: '.9rem'}}>Total Gross</h6>
                    <Input
                      className="text-nowrap"
                      readOnly
                      type="text"
                      id="totalWeightGross"
                      value={tableNumberFormat(
                        parseFloat(
                          productEvent.totalsuma.totalWeightGross
                        )
                      )}
                    ></Input>
                  </td>
                  <td scope="col" className="text-nowrap">
                    <h6 style={{fontSize: '.9rem'}}>Total Net</h6>
                    <Input
                      className="text-nowrap"
                      readOnly
                      type="text"
                      id="totalWeightNet"
                      value={tableNumberFormat(
                        parseFloat(
                          productEvent.totalsuma.totalWeightNet
                        )
                      )}
                    ></Input>
                  </td>
                  <td scope="col" className="text-nowrap">
                    <h6 style={{fontSize: '.9rem'}}>Pallets</h6>
                    <Input
                      className="text-nowrap"
                      readOnly
                      type="text"
                      id="pallets"
                      value={productEvent.totalsuma.pallets}
                    ></Input>
                  </td>
                  <td scope="col" className="text-nowrap"></td>
                  <td scope="col" className="text-nowrap">
                    <h6 style={{fontSize: '.9rem'}}>FOB/FCA</h6>
                    <Input
                      className="text-nowrap"
                      readOnly
                      type="text"
                      id="totalFob"
                      value={dollarUSA(
                        tableNumberFormat(
                          parseFloat(
                            productEvent.totalsuma.totalFobFca
                          )
                        )
                      )}
                    ></Input>
                  </td>
                  <td scope="col" className="text-nowrap">
                    <h6 style={{fontSize: '.9rem'}}>Freight</h6>
                    <Input
                      className="text-nowrap"
                      readOnly
                      type="text"
                      id="freight"
                      value={dollarUSA(
                        tableNumberFormat(
                          parseFloat(productEvent.totalsuma.freight)
                        )
                      )}
                    ></Input>
                  </td>
                  <td scope="col" className="text-nowrap">
                    <h6 style={{fontSize: '.9rem'}}>Insurance</h6>
                    <Input
                      className="text-nowrap"
                      readOnly
                      type="text"
                      id="insurance"
                      value={dollarUSA(
                        tableNumberFormat(
                          parseFloat(productEvent.totalsuma.insurance)
                        )
                      )}
                    ></Input>
                  </td>
                  <td scope="col" className="text-nowrap">
                    <h6 style={{fontSize: '.9rem'}}>Total</h6>
                    <Input
                      className="text-nowrap"
                      readOnly
                      type="text"
                      id="totalFob"
                      value={dollarUSA(
                        tableNumberFormat(
                          parseFloat(productEvent.totalsuma.lastTotal)
                        )
                      )}
                    ></Input>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col>
            <Row>
              <Col className=" d-flex justify-content-center">
                <FormGroup>
                  <Label htmlFor="default-4" className="form-label">
                    Details
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pb-3">
                <FormGroup>
                  <Label htmlFor="default-4" className="form-label">
                    Container
                  </Label>
                  <div className="form-control-wrap">
                    <Input
                      className="text-nowrap form-control"
                      type="number"
                      id="containers_quantity"
                      name="containers_quantity"
                      defaultValue={
                        productEvent.materials.length === 0
                          ? ''
                          : productEvent.materials[0]
                              .containers_quantity
                      }
                      onChange={(e) =>
                        onChangeSelect(e.target, e.target.name)
                      }
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col className="pb-3">
                <FormGroup>
                  <Label htmlFor="default-4" className="form-label">
                    Currency
                  </Label>
                  <div className="form-control-wrap">
                    <CustomSelect
                      id={'exchange_rate_currency_code'}
                      onChange={onChangeSelect}
                      optionsSelect={currencies}
                      placeholder={'Select the currency '}
                      placement={'top'}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
