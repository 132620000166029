/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { Block, Button } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import FileProvider from "./helpers/file.provider";
import { getFormattedSize } from "./helpers/files.helper";


const Helperdocuments = ({ idcliente, codigo }) => {
  const [files, setFiles] = useState([]);
  const [refId, setRefId] = useState(idcliente);
  const [sku, setSku] = useState(codigo);
  const [submitting, setSubmitting] = useState(false);

  const getFiles = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const files = (await new FileProvider().getAssetsFiles()).data.files;
      setFiles(files);
    } catch (reason) {
      console.warn(reason);
    }
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Content>
        <Block>
          <Button color="secondary" variant="contained" onClick={getFiles}>
            {submitting ? "Buscando..." : "Actualizar"}
          </Button>
          <p />
          <span>
            {files.length > 0
              ? "Archivos Recuperados"
              : "Presione el boton de Actualizar"}
          </span></Block>
          <Block className="rounded-pill border border-primary">
          {files.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>File Size</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => {
                  return (
                    <tr key={index}>
                      <td>{file.name + file.extension}</td>
                      <td>{getFormattedSize(file.size, 2)}</td>
                      <td>
                          <a href={file.file} className="link-primary">Download</a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : null}
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Helperdocuments;
