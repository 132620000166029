import axios from 'axios';
import {useState} from 'react';
import {BACKEND} from '../../consts/backend';

export const getRoles = async (user) => {
  let aux = [];
  aux = await axios
    .get(`${BACKEND}/get-role/${user}`)
    .then((response) => response.data)
    .catch((err) => console.warn(err));
  return aux;
};
