import React, {useRef, useEffect, useMemo, useState} from 'react';
import FileProvider from '../../followUp/Components/helpers/file.provider';
import {
  checkFileMIMEType,
  checkFileSize,
  getFormattedSize,
} from '../../followUp/Components/helpers/files.helper';
import Swal from 'sweetalert2';
import {Button} from '../../../components/Component';
import Content from '../../../layout/content/Content';
import axios from 'axios';
import {useAuth0} from '@auth0/auth0-react';
import uploadFiles from '../../followUp/Components/helpers/add_file';
import {nanoid} from 'nanoid';
import {Tooltip} from 'reactstrap';
import excelIntegration from './send-data';
import * as XLSX from 'xlsx';
import Spinner from 'react-bootstrap/Spinner';

const blobToBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const SellerFilesTest = ({
  idcliente,
  skus,
  date,
  setReload,
  reload,
  setData,
  data,
  atribute,
}) => {
  // const { user } = useAuth0()

  const MAXIMUM_FILES_LIMIT = null;
  const MAXIMUM_FILE_SIZE = 20; // Size in MB
  const ALLOWED_FORMATS =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const {user, isAuthenticated, getIdTokenClaims} = useAuth0();

  const [files, setFiles] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const [roles, setRoles] = useState([]);
  
  const [showSpinner, setShowSpinner] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const fileInputRef = useRef(null);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const getCurrentFiles = async (event) => {
    //console.log('user:',user,user.name)
    const rawFiles = event.target.files;
    const fileList = []; // WIll contain an array of type File[]
    if (!rawFiles) {
      return;
    }
    if (rawFiles instanceof Array) {
      rawFiles.forEach((file) => fileList.push(file));
    } else {
      const rawFilesArray = Array.from(rawFiles);
      rawFilesArray.forEach((file) => fileList.push(file));
    }
    const finalFileList = [];
    for (const file of fileList) {
      const extensionSeparatorIndex = file.name.lastIndexOf('.');
      const formattedName = file.name.substring(
        0,
        extensionSeparatorIndex
      );
      const extension = file.name.substr(extensionSeparatorIndex);
      const encodedData = await blobToBase64(file);
      const formattedFile = {
        name: formattedName,
        extension,
        file: encodedData?.toString() ?? '',
        type: 'append',
        size: file.size,
      };
      if (
        files.find(
          (appendedFile) => appendedFile.name === formattedFile.name
        )
      ) {
        Swal.fire({
          icon: 'info',
          title: `The file ${
            formattedFile.name + formattedFile.extension
          } is already added`,
        });
        break;
      }
      if (!checkFileMIMEType(file.type, ALLOWED_FORMATS)) {
        Swal.fire({
          icon: 'warning',
          title:
            'Please select another file (Supported formats: images and PDF)',
          html: `<small class="text-muted">The file ${file.name} is not supported</small>`,
        });
        break;
      }
      if (!checkFileSize(file.size, MAXIMUM_FILE_SIZE)) {
        Swal.fire({
          icon: 'warning',
          title: `The maximum file size is ${MAXIMUM_FILE_SIZE}MB`,
          html: `<small class="text-muted">The file ${
            file.name
          } have a size of ${getFormattedSize(file.size, 2)}</small>`,
        });
        break;
      }
      if (MAXIMUM_FILES_LIMIT) {
        if (files.length < MAXIMUM_FILES_LIMIT) {
          finalFileList.push(file);
        } else {
          Swal.fire({
            icon: 'info',
            title: `The maximum file quantity to upload is ${MAXIMUM_FILES_LIMIT}`,
          });
          break;
        }
      } else {
        const file = event.target.files[0];

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const date = [];
        let w = 0;
        console.log('worksheet:',worksheet)
        for (const i in worksheet) {
         /*console.log(i, (i.includes('B') && (atribute.label === 'ATA' ||
          atribute.label === 'ETA' ||
          atribute.label === 'ATD' ||
          atribute.label === 'ETD' ||
          atribute.label === 'Date Booking' ||
          atribute.label === 'Confirmation Booking' ||
          atribute.label === 'Documental Cut Off' ||
          atribute.label === 'Physical Cut Off' ||
          atribute.label === 'Documental Cut Off (IMO)' ||
          atribute.label === 'VGM Cut Off' ||
          atribute.label === 'Permit (Date)' ||
          atribute.label === 'Document Packet Sent' ||
          atribute.label === 'Custom Transmission Date')), worksheet[i].v)*/
          if (
            i.includes('B') &&
            w >= 0 &&
            (atribute.label === 'ATA' ||
              atribute.label === 'ETA' ||
              atribute.label === 'ATD' ||
              atribute.label === 'ETD' ||
              atribute.label === 'Date Booking' ||
              atribute.label === 'Confirmation Booking' ||
              atribute.label === 'Documental Cut Off' ||
              atribute.label === 'Physical Cut Off' ||
              atribute.label === 'Documental Cut Off (IMO)' ||
              atribute.label === 'VGM Cut Off' ||
              atribute.label === 'Permit (Date)' ||
              atribute.label === 'Document Packet Sent' ||
              atribute.label === 'Custom Transmission Date')
          ) {
            date.push(worksheet[i].v);
            
          }

          if (
            i.includes('B') &&
            (atribute.label === 'ATA' ||
              atribute.label === 'ETA' ||
              atribute.label === 'ATD' ||
              atribute.label === 'ETD' ||
              atribute.label === 'Date Booking' ||
              atribute.label === 'Confirmation Booking' ||
              atribute.label === 'Documental Cut Off' ||
              atribute.label === 'Physical Cut Off' ||
              atribute.label === 'Documental Cut Off (IMO)' ||
              atribute.label === 'VGM Cut Off' ||
              atribute.label === 'Permit (Date)' ||
              atribute.label === 'Document Packet Sent' ||
              atribute.label === 'Custom Transmission Date')
          )
            w += 1;
          if (
            i.includes('C') &&
            worksheet[i].v !== 'DATE' &&
            worksheet[i].v !== 'FECHA' && 
            w === 0
          ) {
            date.push(worksheet[i].v);
            
          }
        }
        if(atribute.label === 'ATA' ||
          atribute.label === 'ETA' ||
          atribute.label === 'ATD' ||
          atribute.label === 'ETD' ||
          atribute.label === 'Date Booking' ||
          atribute.label === 'Confirmation Booking' ||
          atribute.label === 'Documental Cut Off' ||
          atribute.label === 'Physical Cut Off' ||
          atribute.label === 'Documental Cut Off (IMO)' ||
          atribute.label === 'VGM Cut Off' ||
          atribute.label === 'Permit (Date)' ||
          atribute.label === 'Document Packet Sent' ||
          atribute.label === 'Custom Transmission Date'){
          if(worksheet['B1']){
            console.log('worksheet[B1] está definido, eliminando el primer elemento', date);
            date.splice(0, 1);
            console.log('Después de shift, date:', date);
          }
        }
        /*else if(atribute.label.substr(0, 8) === 'Document'){
          if(worksheet['C1']){
            console.log('worksheet[C1] está definido, eliminando el primer elemento', date);
            date.splice(0, 1);
            console.log('Después de shift, date:', date);
          }
        }*/

        let p = 0;
        console.log('date:',date);
        const aux = jsonData.map((element) => {
          let row = {};

          row = {
            target: atribute.prop,
            data: {
              po: Object.values(element)[0],
              value: Object.values(element)[1],
              date: undefined,
              user_name: user.name,
            },
          };
         // console.log(date, atribute.label)
          if (
            atribute.label === 'ATA' ||
            atribute.label === 'ETA' ||
            atribute.label === 'ATD' ||
            atribute.label === 'ETD' ||
            atribute.label === 'Date Booking' ||
            atribute.label === 'Confirmation Booking' ||
            atribute.label === 'Documental Cut Off' ||
            atribute.label === 'Physical Cut Off' ||
            atribute.label === 'Documental Cut Off (IMO)' ||
            atribute.label === 'VGM Cut Off' ||
            atribute.label === 'Permit (Date)' ||
            atribute.label === 'Custom Transmission Date' ||
            atribute.label === 'Document Packet Sent' ||
            (atribute.label.substr(0, 8) === 'Document' && atribute.label != 'Document Packet Sent')
          ) {
            /*const dateO = new Date(date[p]);*/
            /*const [day, month, anio] = date[p].split('/').map(Number); 
            let anio1 = anio < 100 ? 2000 + anio : anio;
            const dateO = new Date(anio1, month - 1, day);*/
            const excelDate = date[p];
            const dateO = new Date((excelDate - 25569) * 86400 * 1000); 
            
            const timezoneOffset = dateO.getTimezoneOffset() * 60000; 
            const correctedDate = new Date(dateO.getTime() + timezoneOffset);

            let year = correctedDate.getFullYear();
            let m = correctedDate.getMonth() + 1;
            let d = correctedDate.getDate();


            if (m < 10) m = `0${m}`;
            if (d < 10) d = `0${d}`;

            let formatted = `${d}/${m}/${year}`;

            if (formatted === 'NaN/NaN/NaN') {
              let [day, month, anio] = date[p].split('/').map(Number);
              if (month < 10) month = `0${month}`;
              if (day < 10) day = `0${day}`;
              formatted = `${day}/${month}/${anio}`/*date[p]*/;
            }

            if (atribute.label.substr(0, 8) === 'Document' && atribute.label != 'Document Packet Sent') {
              row.data.date = formatted;
            } else {
              row.data.value = formatted;
            }
            
            /*console.log('date:',date)
            console.log('dateO:',dateO)
            console.log('correctedDate:',correctedDate);
            console.log('year:',year)
            console.log('m:',m)
            console.log('d:',d)*/
            console.log('formatted',p,':',formatted)
          }
          p += 1;
          return row;
        });
        setData(aux);
      }
    }
    if (finalFileList.length > 0) {
      setFiles([...files, ...finalFileList]);
    }

    event.target.value = event.target.defaultValue; // Reset input cached value
  };

  //
  const handleFilesSubmit = async (event) => {
    event.preventDefault();
    setShowSpinner(true);
    try {
      const response = await excelIntegration(data);
      console.log('response:', response);
      console.log('response.data.state:', response.data.state);
  
      // Verificar que response.data esté definido
      if (response.data && response.data.state) {
        await Swal.fire({
          title: `${files.length > 1 ? 'Files' : 'File'} uploaded successfully`,
          icon: 'success',
        });
        setFiles([]);
        setShowSpinner(false);
      } else {
        Swal.fire({
          title: 'Oops!!',
          text: response.error || 'Unknown error occurred',
          icon: 'warning',
        });
        setShowSpinner(false);
      }
    } catch (error) {
      // Manejar cualquier error que ocurra durante la llamada a excelIntegration
      console.error('Error during file submission:', error);
      Swal.fire({
        title: 'Oops!!',
        text: 'An error occurred while submitting the files.',
        icon: 'error',
      });
      setShowSpinner(false);
    }
  };

  // remove file
  const removeFile = (event, index) => {
    if (event.isTrusted) {
      const currentFiles = Object.create(files);
      currentFiles.splice(index, 1);
      setFiles([...currentFiles]);
    }
  };

  const spinner = () => {
    return <div style={{ display: 'flex', alignItems: 'center' }}>
    <Spinner animation="border" style={{ marginLeft: '15px' }} />
    <span style={{ marginLeft: '10px' }}>Processing...</span>
  </div>;
  };

  return (
    <React.Fragment>
      {/* {!isReadOnly && ( */}
      <form onSubmit={handleFilesSubmit}>
        <div className="input-group">
            <div className="custom-file" id="file-upload-container">
               <input
                    type="file"
                    className="custom-file-input"
                    onChange={getCurrentFiles}
                    id="file-uploader"
                    ref={fileInputRef}
                  />
              <label
                className="custom-file-label"
                htmlFor="file-uploader"
              >
                Choose files
              </label>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="file-upload-container"
                toggle={toggleTooltip}
              >
                Every Document Column Must Have a Related Header And Be In Text Format.
              </Tooltip>
            </div>
          
        </div>
        <div className="row mt-3">
          <div className="col d-flex align-items-center">
            {data.length !== 0 && (
              <>
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  type="submit"
                >
                  Upload listed files
                </Button>
                
                {showSpinner === true ? (
                  <>
                    {spinner()}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </form>
      {/* )} */}
    </React.Fragment>
  );
};
