import {useAuth0} from '@auth0/auth0-react';
import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Container,
  Nav,
  NavItem,
  Table,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import {Modal} from 'react-bootstrap';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  PreviewAltCard,
  Row,
  Icon,
} from '../../components/Component';
import {nanoid} from 'nanoid';
import UserProvider from '../helpers/userProvider';
import {getRoles} from '../helpers/getRole';
import {UserEdit} from './UserEdit';
import Head from '../../layout/head/Head';
import {EnterpriseManagement} from './EnterpriseManagement';
import {UserManagementBoard} from './UserManagement';
import {CreateUser} from './CreateUser';
import {CreateEnterprise} from './CreateEnterprise';
import SelectProvider from '../../Exportation/followUp/Components/helpers/providerSelects';

export const UsersBoard = () => {
  const [users, setUsers] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [enterprise, setEnterprise] = useState([]);
  const [show, setShow] = useState(false);
  const [selectdUser, setSelectUser] = useState({});

  const [activeIconTab, setActiveIconTab] = useState('1');

  const handleClose = () => {
    setShow(false);
  };

  useEffect(async () => {
    let aux;
    let aux2;
    new UserProvider().getRoles().then((response) => {
      let aux = response.map((element) => ({
        label: element.name,
        value: element.id,
      }));
      setRoles(aux);
    });

    await new UserProvider().getUsers().then((response) => {
      aux = response.map((element) => {
        let nameAux = element.nickname.split(' ', 2);
        return {
          email: element.email,
          first_name: nameAux[0],
          last_name: nameAux[1] || '',
          avatar: element.picture,
          id: element.user_id,
          rol: '',
          enterprise: [],
        };
      });
      aux2 = response.map((element) => ({
        value: element.user_id,
        label: element.nickname,
      }));
      setUsers(aux);
      setUsersOptions(aux2);
    });

    new SelectProvider().getCompanies().then((element) => {
      setEnterprise(element);
    });
  }, []);
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  const header = ['id'];
  const {user} = useAuth0();
  return (
    <React.Fragment>
      {' '}
      <Container>
        <Head title="Reports - SSB SYSTEM"></Head>
        <BlockHead size="sm">
          <br />
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="text-ssbnaranja" tag="h3" page>
                User Management Dashboard
                <strong className="text-primary small"></strong>
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({
                          active: activeIconTab === '1',
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab('1');
                        }}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>Users</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({
                          active: activeIconTab === '2',
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab('2');
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <Icon name="user-circle"></Icon>
                        <span>Create User</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({
                          active: activeIconTab === '3',
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab('3');
                        }}
                      >
                        <Icon name="coffee"></Icon>
                        <span>Enterprise</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({
                          active: activeIconTab === '4',
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab('4');
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <Icon name="coffee"></Icon>
                        <span>Create Enterprise</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </ul>
                {users.length != 0 ? (
                  <TabContent activeTab={activeIconTab}>
                    <TabContent activeTab={activeIconTab}>
                      <TabPane tabId="1">
                        <UserManagementBoard
                          users={users}
                          roles={roles}
                          enterprise={enterprise}
                        />
                      </TabPane>
                    </TabContent>
                    <TabContent activeTab={activeIconTab}>
                      <TabPane tabId="2">
                        <CreateUser
                          roles={roles}
                          enterprise={enterprise}
                        />
                      </TabPane>
                    </TabContent>
                    <TabContent activeTab={activeIconTab}>
                      <TabPane tabId="3">
                        <EnterpriseManagement
                          enterprise={enterprise}
                          users={usersOptions}
                        />
                      </TabPane>
                    </TabContent>
                    <TabContent activeTab={activeIconTab}>
                      <TabPane tabId="4">
                        <CreateEnterprise users={usersOptions} />
                      </TabPane>
                    </TabContent>
                  </TabContent>
                ) : null}
              </div>
            </div>
          </Card>
        </Block>
      </Container>
      <Modal
        show={show}
        size="xl"
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <UserEdit user={selectdUser} />
      </Modal>
    </React.Fragment>
  );
};
