import HttpClient from './providers.helper';
import {BACKEND} from '../../../../consts/backend';
class SelectProvider {
  httpClient = HttpClient;

  async getplants() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/plants`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getcities() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/cities`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getincoterms() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/incoterms`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getcarriers() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/carriers`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getvessels() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/expo_vessel`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async gettransportation_modes() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/transportation_modes`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getcountries() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/countries`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getcurrencies() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/currencies`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getcontainers() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/containers`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getcontainerstype() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/container-types`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getportalterminaltrasportation() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/portal-terminal-transpotation`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getcustombreak() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/customs_broker`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getreference(id) {
    return await this.httpClient
      .get(`${BACKEND}/expo-301/get-reference/${id}`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getcustom() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/customs`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getbank() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/banks`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getdowport() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/dow_ports`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getGmid() {
    return await this.httpClient
      .get(`${BACKEND}/expo/get-material-gm-id`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getAta() {
    return await this.httpClient
      .get(`${BACKEND}/expo/ata-transport`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getPucharseType() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/pucharse-type`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getCompanies() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/companies`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
  async getVendors() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/vendors`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
}

export default SelectProvider;
