import {nanoid} from 'nanoid';
import React, {useState} from 'react';
import {Container, FormGroup, Label} from 'reactstrap';
import Swal from 'sweetalert2';
import {Button} from '../../components/Component';
import {
  checkFileMIMEType,
  checkFileSize,
  getFormattedSize,
} from '../../listgsa/helpers/files.helper';

const blobToBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const ImageFile = ({}) => {
  const MAXIMUM_FILES_LIMIT = null;
  const MAXIMUM_FILE_SIZE = 800; // Size in MB
  const ALLOWED_FORMATS = 'image/*';

  const [imagedata, setImagedata] = useState([]);

  const getCurrentFiles = async (event) => {
    const rawFiles = event.target.files;
    const fileList = [];
    if (!rawFiles) {
      return;
    }
    if (rawFiles instanceof Array) {
      rawFiles.forEach((file) => fileList.push(file));
    } else {
      const rawFilesArray = Array.from(rawFiles);
      rawFilesArray.forEach((file) => fileList.push(file));
    }
    const finalFileList = [];
    for (const file of fileList) {
      const extensionSeparatorIndex = file.name.lastIndexOf('.');
      const formattedName = file.name.substring(
        0,
        extensionSeparatorIndex
      );
      const extension = file.name.substr(extensionSeparatorIndex);
      const encodedData = await blobToBase64(file);
      const formattedFile = {
        name: formattedName,
        extension,
        file: encodedData?.toString() ?? '',
        type: 'append',
        size: file.size,
      };
      if (!checkFileMIMEType(file.type, ALLOWED_FORMATS)) {
        Swal.fire({
          icon: 'warning',
          title:
            'Please select another file (Supported formats: images)',
          html: `<small class="text-muted">The file ${file.name} is not supported</small>`,
        });
        break;
      }
      if (!checkFileSize(file.size, MAXIMUM_FILE_SIZE)) {
        Swal.fire({
          icon: 'warning',
          title: `The maximum file size is ${MAXIMUM_FILE_SIZE}MB`,
          html: `<small class="text-muted">The file ${
            file.name
          } have a size of ${getFormattedSize(file.size, 2)}</small>`,
        });
        break;
      }
      if (MAXIMUM_FILES_LIMIT) {
        if (imagedata.length < MAXIMUM_FILES_LIMIT) {
          finalFileList.push(file);
        } else {
          Swal.fire({
            icon: 'info',
            title: `The maximum file quantity to upload is ${MAXIMUM_FILES_LIMIT}`,
          });
          break;
        }
      } else {
        finalFileList.push(file);
      }
    }
    if (finalFileList.length > 0) {
      setImagedata([...finalFileList]);
    }
    event.target.value = event.target.defaultValue;
  };

  // remove file
  const removeFile = (event, index) => {
    if (event.isTrusted) {
      const currentFiles = Object.create(imagedata);
      currentFiles.splice(index, 1);
      setImagedata([...currentFiles]);
    }
  };

  return (
    <FormGroup>
      <input
        type="file"
        className="custom-file-input"
        id="file-uploader"
        onChange={getCurrentFiles}
      />
      <label className="custom-file-label" htmlFor="file-uploader">
        Choose files
      </label>
      {imagedata.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th className="text-info">File Name</th>
              <th className="text-info">File Size</th>
              <th className="text-info">Actions</th>
            </tr>
          </thead>
          <tbody>
            {imagedata.map((file, index) => (
              <tr key={nanoid()}>
                <td>{file.name}</td>
                <td>{getFormattedSize(file.size, 2)}</td>
                <td>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={(event) => removeFile(event, index)}
                    //disabled={submitting}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FormGroup>
  );
};
