import {BACKEND} from '../../consts/backend';
const GetFollowupExpo = async () => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const resp = await fetch(
    `${BACKEND}/expo/all_followup`,
    requestOptions
  );
  const sku = await resp.json();

  return sku;
};

export default GetFollowupExpo;
