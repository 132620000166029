import {Fragment, useState} from 'react';
import {
  Button,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Table,
  Col,
  Row,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import {Block, Icon} from '../../components/Component';
import {nanoid} from 'nanoid';
import {ImageFile} from './ImageFile';
import CustomSelect from '../../Exportation/followUp/Components/customselect';

export const EnterpriseManagement = ({enterprise, users}) => {
  const data = [
    {name: 'enteprice test', code: 'DF546DFD', miembros: []},
    {name: 'enteprice test1', code: 'DF5566DFD', miembros: []},
    {name: 'enteprice test2', code: 'DF546DFD', miembros: []},
    {name: 'enteprice test3', code: 'DF757FD', miembros: []},
    {name: 'enteprice test4', code: 'D56566DFD', miembros: []},
  ];

  const [show, setShow] = useState(false);
  const [selectEnterprise, setSelectEnterprise] = useState(false);

  const onChange = (e, id, index, name) => {};

  return (
    <Fragment>
      <Container>
        <Block>
          <Row>
            <Col
              lg="12"
              className="pb-3"
              style={{
                height: '40rem',
                overflowX: 'auto',
              }}
            >
              <Table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap">
                      Image
                    </th>
                    <th scope="col" className="text-nowrap">
                      Name
                    </th>
                    <th scope="col" className="text-nowrap">
                      Accion
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((element) => (
                    <tr key={nanoid()}>
                      <td scope="col" className="text-nowrap">
                        <img
                          src={element.avatar}
                          alt="Avatar"
                          width={'10%'}
                          style={{
                            borderRadius: '50%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '1rem',
                          }}
                        ></img>
                      </td>
                      <td scope="col" className="text-nowrap">
                        {element.name}
                      </td>
                      <td>
                        <Row>
                          <a
                            href="#"
                            onClick={(e) => {
                              setShow(true);
                              setSelectEnterprise(element);
                            }}
                          >
                            <h5>
                              <Icon
                                className="text-ssbazul"
                                name="edit"
                              />
                            </h5>
                          </a>
                        </Row>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Block>
      </Container>
      <Modal
        isOpen={show}
        toggle={() => setShow(false)}
        size="xl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <ModalHeader toggle={() => setShow(false)}>
            Edit Enteprise {selectEnterprise?.name}
          </ModalHeader>
          <div className="p-2">
            <div className="tab-content">
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-label" htmlFor="name">
                      Name
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      id="nickname"
                      defaultValue={selectEnterprise?.name}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label className="form-label" htmlFor="nameUser">
                    Image
                  </Label>
                  <ImageFile />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label className="form-label" htmlFor="nameUser">
                      Users
                    </Label>
                    <CustomSelect
                      optionsSelect={users}
                      defaultValueSelect={[]}
                      onChange={onChange}
                      multi={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button>Submit</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
