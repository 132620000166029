import {BACKEND} from '../../consts/backend';

const GetStatus = async () => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const resp = await fetch(`${BACKEND}/getstatus`, requestOptions);
  const sku = await resp.json();

  return sku;
};

export default GetStatus;
