import HttpClient from '../../plasticos/helpers/providers.helper';
import {BACKEND} from '../../consts/backend';

class UserProvider {
  httpClient = HttpClient;

  async getUsers() {
    return await this.httpClient
      .get(`${BACKEND}/get-users`)
      .then((result) => result.data)
      .catch((err) => err);
  }

  async getRol(user) {
    return await this.httpClient
      .get(`${BACKEND}/get-role/${user}`)
      .then((result) => result.data)
      .catch((err) => err);
  }

  async getRoles() {
    return await this.httpClient
      .get(`${BACKEND}/get-all-role`)
      .then((result) => result.data)
      .catch((err) => err);
  }
}

export default UserProvider;
