/* eslint-disable no-unused-vars */
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
const Error404Modern = lazy(() => import("./pages/error/404-modern"));

ReactDOM.render(
  <React.Fragment>
    <Suspense fallback={<div />}>
      <Router basename={`/`}>
        <Auth0Provider
          domain="dev-dc50ste8k7pnk7t0.us.auth0.com"
          clientId="taZgMnlssOAbnrXW8llErvGW34bcyr9Q" 
        /*  domain="gsarenewed.us.auth0.com"
          clientId="33UbLKJ0Yn4OymTKrY9lqmEiuXbmpOsd"*/
          redirectUri={window.location.origin}
        >
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </Auth0Provider>
      </Router>
    </Suspense>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
