/* eslint-disable no-unused-vars */
import {React, lazy} from 'react';
import PrivateRoute from './route/PrivateRoute';
import Layout from './layout/Index';
import {withAuthenticationRequired} from '@auth0/auth0-react';

import {Switch, Route} from 'react-router-dom';
import {RedirectAs404} from './utils/Utils';

const Faq = lazy(() => import('./pages/others/Faq'));
const Terms = lazy(() => import('./pages/others/Terms'));

const Error404Classic = lazy(() =>
  import('./pages/error/404-classic')
);
const Error404Modern = lazy(() => import('./pages/error/404-modern'));
const Error504Modern = lazy(() => import('./pages/error/504-modern'));
const Error504Classic = lazy(() =>
  import('./pages/error/504-classic')
);
const Register = lazy(() => import('./pages/auth/Register'));
const ForgotPassword = lazy(() =>
  import('./pages/auth/ForgotPassword')
);
const Success = lazy(() => import('./pages/auth/Success'));
const InvoicePrint = lazy(() =>
  import('./pages/pre-built/invoice/InvoicePrint')
);

const DetallesdelSeller = lazy(() =>
  import('./listgsa/components/detallesdelseller')
);
const DetailOrder = lazy(() =>
  import('./plasticos/components/detailorder')
);
const PlasticOrigin = lazy(() =>
  import('./dashimpo/components/plasticorigin')
);

const App = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auth-success`}
          component={Success}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auth-reset`}
          component={ForgotPassword}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auth-register`}
          component={Register}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/invoice-print/:id`}
          component={InvoicePrint}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auths/terms`}
          component={Terms}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auths/faq`}
          component={Faq}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/invoice-print`}
          component={InvoicePrint}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/errors/404-classic`}
          component={Error404Classic}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/errors/504-modern`}
          component={Error504Modern}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/errors/404-modern`}
          component={Error404Modern}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/errors/504-classic`}
          component={Error504Classic}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/detalleseller/:id`}
          render={(props) => <DetallesdelSeller {...props} />}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/detailorder/:id`}
          render={(props) => <DetailOrder {...props} />}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/detallesorders/:id`}
          render={(props) => <PlasticOrigin {...props} />}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/listplasticos`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/calculator`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/listgsa`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/followup`}
          component={Layout}
        ></Route>

        {/* test */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/containerExport`}
          component={Layout}
        ></Route>
        {/*  */}

        {/* test */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/follow-up-report`}
          component={Layout}
        ></Route>
        {/* user management */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user_management`}
          component={Layout}
        ></Route>
        {/*  */}

        {/* MacroEdit */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/macroUpdate`}
          component={Layout}
        ></Route>
        {/*  */}

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/powerbi`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dashimpo`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/reportImpo`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/followUpExpo`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/followUpExpoDemo`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/reportExpo`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dashboardExpo`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/expo-detailOrder/:po`}
          component={Layout}
        ></Route>
        {/* demo new order*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/newOrder`}
          component={Layout}
        ></Route>
        {/* demo new order*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/expo-detailOrderDemo/:po`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/arrivals`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/arrivals2`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dragreport`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dragreport/:reportId`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/reportList`}
          component={Layout}
        ></Route>
         <Route
          exact
          path={`${process.env.PUBLIC_URL}/muestras`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/viewCustomReport/:reportId`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dragreportexport`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/expo/master-db`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/subir-excel`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dragreportexport/:reportId`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/help-documents`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/exporeportlist`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/viewExpoCustomReport/:reportId`}
          component={Layout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={Layout}
        />
        <PrivateRoute exact path="" component={Layout}></PrivateRoute>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </>
  );
};
export default withAuthenticationRequired(App);
