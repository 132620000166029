/* eslint-disable react-hooks/exhaustive-deps */
import {useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Button, Col, Form, Row, Table} from 'react-bootstrap';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../../../components/Component';
import Content from '../../../layout/content/Content';
import useGetFollowup from '../UseGetFollowup';
import {
  BaseReportFilterOperator,
  CustomReport,
  DateReportFilterOperator,
  FieldTypes,
  FieldTypeSpec,
  FilterGroupList,
  NumericReportFilerOperator,
  StringReportFilterOperator,
} from './types/report-types';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import Head from '../../../layout/head/Head';
//import { CustomReportProvider } from "../../../providers/custom-report.provider";
import {CustomReportProviderExport} from '../../providers/custom-report.provider';
import {useParams} from 'react-router-dom';
import {nanoid} from 'nanoid';
import * as XLSX from 'xlsx';

const ViewExpoCustomReport = () => {
  const headersNames = [
    {label: 'Sale Order', prop: 'shipment'},
    {label: 'PO Number', prop: 'po'},
    {label: 'SSB Ref.', prop: 'numeroInterno'},
    {label: 'Invoice', prop: 'invoice_number'},
    {label: 'Shipper', prop: 'shipper'},
    {label: 'Consignee', prop: 'consignee'},
    {label: 'Product ID', prop: 'material_id'},
    {label: 'Product Description', prop: 'material_name'},
    {label: 'Freight BL', prop: 'materials_freight'},
    {label: 'Plant Exit Date - Estimated', prop: 'ETD'},
    {label: 'Plant Exit Date - Actual', prop: 'ATD'},
    {label: 'ETD Date', prop: 'ETD'},
    {label: 'ATD Date', prop: 'ATD'},
    {label: 'ETA Date', prop: 'ETA'},
    {label: 'ATA Date', prop: 'ATA'},
    {label: 'Original ETA - Date', prop: 'ETA'},

    {label: 'EntryNumber', prop: 'dispatch_number'},

    {
      label: 'Customs Transmission Date',
      prop: 'custom_transmition_date',
    },

    {label: 'Customs Clearance Date', prop: 'permition_date'},

    {
      label: 'Doc sent - Date',
      prop: 'material_document_reception_date',
    },

    {label: 'Note', prop: 'ref_notes'},

    {label: 'Carrier', prop: 'carrier_name'},
    {label: 'Booking Number', prop: 'booking_number'},
    {
      label: 'Booking Confirmation Date',
      prop: 'booking_date_confrimation',
    },
    {label: 'Destination', prop: 'destination'},

    {label: 'Country of destinations', prop: 'country_name'},

    {label: 'Modal', prop: 'transportation_mode_name'},

    {label: 'Type of Cargo', prop: 'material_equipment_type'},

    {label: 'Netwight KG-Shipment', prop: 'material_weight_net_uom'},

    {label: 'Register Date', prop: 'order_date'},

    {label: 'Plant ID', prop: 'plant_code'},

    {label: 'PDF - AR Consolidated Doc', prop: 'consolidated'},
    {label: 'PDF - BL', prop: 'bl'},
    {label: 'PDF - Invoice', prop: 'invoice'},
    {label: 'PDF - RE', prop: 're'},

    {
      label: 'Dow Audit Date - Argentina Only',
      prop: 'argentina_only',
    },
    {label: 'Screen Maria System', prop: 'maria_system'},
    {label: 'FOB', prop: 'total_price'},
    {label: 'MOT', prop: 'transportation_mode_name'},
    {label: 'EQ', prop: 'containers_quantity'},
    {label: 'Unit', prop: 'material_unit_price'},
    {label: 'GW KG-Shipment', prop: 'material_weight_gross_uom'},
    {label: 'Business (Negocio)', prop: 'business'},
    {label: 'Business Group', prop: 'business_group_name'},
    {label: 'Business (AOB)', prop: 'business_name'},
    {label: 'COO', prop: 'seal_number'},
    {label: 'POL', prop: 'port_terminal_port_loading_name'},
    {label: 'NCM', prop: 'material_ncm'},
    {label: '----', prop: 'shipment_terminal'},
  ];

  const [filterGroup, setFilterGroup] = useState<FilterGroupList[]>(
    []
  );
  const {data: returnedOperationsList} = useGetFollowup(); // Aqui borre que a returnedOperationsList se le iguala un [] vacio
  const [searchByPO, wantToSearchByPO] = useState<boolean>();
  const {reportId} = useParams<{reportId?: string}>();
  const poNumberInputRef = useRef<HTMLInputElement>(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [allData, setAllData] = useState<any[]>();
  const [poNumber, setPoNumber] = useState('');
  const [currentReport, setCurrentReport] = useState<CustomReport>({
    creator: '',
    description: '',
    name: '',
    spec: {
      Filters: [],
      selectedColumn: [],
    },
    created_at: '',
  });

  const getCurrentReport = () => {
    CustomReportProviderExport.getReportById(reportId ?? '').then(
      (result) => {
        if (result.data?.current) {
          const currentResult = result.data?.current as CustomReport;
          const reportToView = currentResult;
          setCurrentReport(reportToView);
          setFilterGroup(reportToView.spec.Filters);
        }
      }
    );
  };

  const getOperationList = () => {
    if (
      returnedOperationsList &&
      returnedOperationsList instanceof Array &&
      returnedOperationsList.length > 0
    ) {
      setAllData(returnedOperationsList);
      setShowSpinner(false);
    }
  };

  //Comienzo lógica de filtros
  const getFilterValidator = (
    operator:
      | DateReportFilterOperator
      | BaseReportFilterOperator
      | StringReportFilterOperator
      | NumericReportFilerOperator,
    data: string | null | number,
    comparison?: {
      firstValue: string | null | number;
      secondValue?: string | null | number;
    }
  ) => {
    const now = new Date(new Date().setHours(0, 0, 0, 0));
    const firstDayWeek = now.getUTCDate() - now.getUTCDay();
    const lastDayWeek = new Date(
      new Date().setUTCDate(firstDayWeek + 7)
    );
    const sunday = new Date(now.setUTCDate(firstDayWeek - 1));
    const firsDayMonth = new Date(
      new Date(
        new Date().setUTCFullYear(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          1
        )
      ).setUTCHours(0, 0, 0, 0)
    );
    const lastDayMonth = new Date(
      new Date(
        new Date().setUTCFullYear(
          now.getUTCFullYear(),
          now.getUTCMonth() + 1,
          1
        )
      ).setUTCHours(0, 0, 0, 0)
    );
    switch (operator) {
      case 'is null':
        return data === null;
      case 'is not null':
        return data !== null;
      case 'is not empty':
        return data !== ' ' || !isNaN(Number(data)) || data !== null;
      case 'is empty':
        return data === ' ' || isNaN(Number(data)) || data === null;
      case 'next week':
        const nextWeek = new Date(data as string);
        return (
          lastDayWeek <
            new Date(now.setDate(nextWeek.getUTCDate() + 1)) &&
          new Date(now.setDate(lastDayWeek.getUTCDate() + 6)) >
            nextWeek
        );
      case 'prev week':
        const prevWeek = new Date(data as string);
        return (
          sunday > prevWeek &&
          new Date(now.setDate(sunday.getUTCDate() - 6)) < prevWeek
        );
      case 'next month':
        const nextMonth = new Date(data as string);
        return (
          lastDayMonth <= nextMonth &&
          new Date(
            new Date(
              new Date().setUTCFullYear(
                now.getUTCFullYear(),
                now.getUTCMonth() + 2,
                1
              )
            ).setUTCHours(0, 0, 0, 0)
          ) > nextMonth
        );
      case 'this month':
        const thisMonth = new Date(data as string);
        return firsDayMonth <= thisMonth && thisMonth < lastDayMonth;
      case 'prev month':
        const prevMonth = new Date(data as string);
        return (
          new Date(
            new Date(
              new Date().setUTCFullYear(
                now.getUTCFullYear(),
                now.getUTCMonth() - 1,
                1
              )
            ).setUTCHours(0, 0, 0, 0)
          ) <= prevMonth && prevMonth < firsDayMonth
        );
      case 'in':
        return (
          data?.toString().toLowerCase() ===
          (comparison?.firstValue as string)
            .toString()
            .toLocaleLowerCase()
        );
      case 'not in':
        return (
          data?.toString().toLowerCase() !==
          (comparison?.firstValue as string)
            .toString()
            .toLocaleLowerCase()
        );
      case 'begins with':
        return data
          ?.toString()
          .toLowerCase()
          .startsWith(
            (comparison?.firstValue as string)
              .toString()
              .toLowerCase() as string
          );
      case "doesn't begin with":
        return !data
          ?.toString()
          .toLowerCase()
          .startsWith(
            (comparison?.firstValue as string)
              .toString()
              .toLowerCase() as string
          );
      case 'contains':
        return data
          ?.toString()
          .toLowerCase()
          .includes(
            (comparison?.firstValue as string)
              .toString()
              .toLowerCase() as string
          );
      case "doesn't contain":
        return !data
          ?.toString()
          .toLowerCase()
          .includes(
            (comparison?.firstValue as string)
              .toString()
              .toLowerCase() as string
          );
      case 'ends with':
        return data
          ?.toString()
          .toLowerCase()
          .endsWith(
            (comparison?.firstValue as string)
              .toString()
              .toLowerCase() as string
          );
      case "doesn't end with":
        return !data
          ?.toString()
          .toLowerCase()
          .endsWith(
            (comparison?.firstValue as string)
              .toString()
              .toLowerCase() as string
          );
      case 'greater':
        return (
          (data as number) >
          parseInt(comparison?.firstValue as string)
        );
      case 'less':
        return (
          (data as number) <
          parseInt(comparison?.firstValue as string)
        );
      case 'equal':
        return (
          data?.toString().toLowerCase() ===
          (comparison?.firstValue as string)
            .toString()
            .toLocaleLowerCase()
        );
      case 'not equal':
        return (
          data?.toString().toLowerCase() !==
          (comparison?.firstValue as string)
            .toString()
            .toLocaleLowerCase()
        );
      case 'between':
        return (
          ((data as number) >=
            parseInt(comparison?.firstValue as string) &&
            (data as number) <=
              parseInt(comparison?.secondValue as string)) ||
          ((data as number) <=
            parseInt(comparison?.firstValue as string) &&
            (data as number) >=
              parseInt(comparison?.secondValue as string))
        );
      case 'not between':
        return (
          (data as number) <
            parseInt(comparison?.firstValue as string) ||
          (data as number) >
            parseInt(comparison?.secondValue as string)
        );
      default:
        return false;
    }
  };

  const isDataFiltered = (
    data: any,
    filters: FieldTypeSpec<FieldTypes>[],
    logicalGate: string
  ) => {
    let isThere = false;
    const and: boolean[] = [];

    for (const filter of filters) {
      let i = 0;
      while (headersNames[i].label !== filter.criteria) {
        i += 1;
      }

      let prop = headersNames[i].prop;

      if (filter.comparators) {
        if (logicalGate === 'and') {
          if (
            getFilterValidator(
              filter.operator as
                | DateReportFilterOperator
                | BaseReportFilterOperator
                | StringReportFilterOperator
                | NumericReportFilerOperator,
              data[prop],
              filter.comparators
            )
          ) {
            and.push(true);
          } else {
            and.push(false);
          }
        } else {
          if (
            getFilterValidator(
              filter.operator as
                | DateReportFilterOperator
                | BaseReportFilterOperator
                | StringReportFilterOperator
                | NumericReportFilerOperator,
              data[prop],
              filter.comparators
            )
          ) {
            isThere = true;
          }
        }
      } else {
        if (filter.logicalGate === 'and') {
          if (
            getFilterValidator(
              filter.operator as
                | DateReportFilterOperator
                | BaseReportFilterOperator
                | StringReportFilterOperator,
              data[prop]
            )
          ) {
            and.push(true);
          } else {
            and.push(false);
          }
        } else {
          if (
            getFilterValidator(
              filter.operator as
                | DateReportFilterOperator
                | BaseReportFilterOperator
                | StringReportFilterOperator,
              data[prop]
            )
          ) {
            isThere = true;
          }
        }
      }
    }

    if (
      and.length > 0 &&
      and.find((a) => a === false) === undefined
    ) {
      isThere = true;
    }
    return isThere;
  };

  const applyFilterRemaster = (
    group: FilterGroupList[],
    filteredData?: any[][]
  ) => {
    let dataFiltered: any[][] = [];
    for (const filterGroup of group) {
      dataFiltered = [
        ...(filteredData ?? (allData as string[][])).filter(
          (operation) =>
            isDataFiltered(
              operation,
              filterGroup.filter as [],
              filterGroup.logicalGate ?? ''
            )
        ),
        ...dataFiltered,
      ];
      if (filterGroup?.group && filterGroup.group.length > 1) {
        dataFiltered = [
          ...applyFilterRemaster(
            filterGroup.group,
            dataFiltered.length > 0 ? dataFiltered : allData
          ),
        ];
      }
    }
    return dataFiltered;
  };

  const searchPoByNumber = () => {
    if (poNumber) {
      wantToSearchByPO(true);
    }
  };
  const dataToList = useMemo(() => {
    if (allData) {
      const newData = applyFilterRemaster([...filterGroup]) ?? [];
      if (poNumber && !!searchByPO) {
        const filteredDataByPO = [...newData].filter((row: any) => {
          return String(row.po)
            .toLowerCase()
            .includes(String(poNumber).toLowerCase());
        });
        return filteredDataByPO;
      } else {
        if (filterGroup[0]?.logicalGate === '') {
          return allData;
        }
      }
      return newData;
    }
  }, [allData, filterGroup, poNumber, searchByPO]);

  const BorderExample = () => {
    return <Spinner animation="border" />;
  };

  const exportData = () => {
    Swal.fire({
      title: 'You want to export the report?',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      denyButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        const targetTable = document.getElementById('reportsTable');
        const workbook = XLSX.utils.table_to_book(targetTable, {
          sheet: 'sheet1',
        });
        return XLSX.writeFileXLSX(
          workbook,
          `${currentReport.name}.xlsx`
        );
      }
    });
  };

  useLayoutEffect(() => {
    getCurrentReport();
  }, []);

  useLayoutEffect(() => {
    getOperationList();
  }, [returnedOperationsList]);

  useLayoutEffect(() => {
    if (searchByPO && !poNumber) wantToSearchByPO(false);
  }, [dataToList]);

  return (
    <>
      <Content>
        <Head title="Reports - SSB SYSTEM"></Head>
        <BlockHead size="sm" className="" wide="">
          <BlockBetween className="" size="">
            <BlockHeadContent className="">
              <BlockTitle className="text-ssbnaranja" page="">
                Exportation - Customized report
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className="mt-3" size="">
          <div className="card-stretch">
            <div className="card-inner border-primary">
              <h5 className="text-ssbazul">
                View report:{' '}
                <span className="text-muted">
                  {currentReport.name}
                </span>
              </h5>
              <div className="d-flex justify-content-end mb-4">
                <div className="pr-3">
                  <Button
                    variant="secondary"
                    type="Button"
                    style={{width: '120px'}}
                    onClick={() => exportData()}
                    className="d-flex justify-content-center"
                  >
                    Export Excel
                  </Button>
                </div>
                <div>
                  <Button
                    variant="secondary"
                    type="Button"
                    style={{width: '120px'}}
                    onClick={() => {
                      if (poNumberInputRef.current) {
                        poNumberInputRef.current.value = '';
                        setPoNumber('');
                      }
                    }}
                    className="d-flex justify-content-center"
                  >
                    Reset Data
                  </Button>
                </div>
              </div>
              <div className="mt-3">
                <Form>
                  <Row>
                    <Col lg="10" md="10" sm="10">
                      <Form.Group
                        className="mb-3"
                        controlId="searchByPoId"
                      >
                        <Form.Control
                          ref={poNumberInputRef}
                          type="text"
                          placeholder="Search by PO"
                          onChange={(e) =>
                            setPoNumber(e.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="2" md="2" sm="2">
                      <div className="d-flex justify-content-end">
                        <div className="pl-1">
                          <Button
                            variant="secondary"
                            type="Button"
                            onClick={searchPoByNumber}
                            style={{width: '180px'}}
                            className="d-flex justify-content-center"
                          >
                            Search
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div
                style={{
                  height: '40rem',
                  overflowY: 'auto',
                  display: 'block',
                }}
              >
                {showSpinner === true ? (
                  <Table
                    className="table"
                    id="reportsTable2"
                    striped
                    bordered
                    hover
                    size="sm"
                  >
                    <tbody>
                      <tr>
                        <th
                          colSpan={
                            (currentReport?.spec.selectedColumn
                              ?.length || 0) + 1
                          }
                        >
                          <span className="d-flex justify-content-center mt-3 mb-3">
                            {BorderExample()}
                          </span>
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Table
                    className="table"
                    id="reportsTable"
                    striped
                    bordered
                    hover
                    size="sm"
                  >
                    <thead>
                      <tr>
                        {currentReport?.spec.selectedColumn.map(
                          (option: any, labelIndex: number) => (
                            <th scope="col" key={nanoid()}>
                              {headersNames[option.index].label}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {dataToList?.length === 0 ? (
                        <tr>
                          <th scope="col">No results found</th>
                          {currentReport?.spec.selectedColumn?.map(
                            (_: any, labelIndex: number) => (
                              <th scope="col" key={nanoid()}>
                                No data found
                              </th>
                            )
                          )}
                        </tr>
                      ) : (
                        dataToList?.map(
                          (
                            operation: any,
                            operationIndex: number
                          ) => (
                            <tr key={nanoid()}>
                              {currentReport?.spec.selectedColumn?.map(
                                (header: any, propIndex: number) => (
                                  <td key={nanoid()}>
                                    {typeof operation[header.prop] ===
                                      'string' &&
                                    operation[header.prop].trim() ===
                                      '#'
                                      ? ''
                                      : !operation[header.prop]
                                      ? ''
                                      : String(
                                          operation[header.prop]
                                        )}
                                  </td>
                                )
                              )}
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </div>
        </Block>
      </Content>
    </>
  );
};

export default ViewExpoCustomReport;
