import React from 'react';
import {DashboardMacroEdit} from './components/ComponentEdit';

export const MacroUpdate = () => {
  return (
    <React.Fragment>
      <DashboardMacroEdit></DashboardMacroEdit>
    </React.Fragment>
  );
};
