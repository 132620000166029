
import React from "react";
import Principal from "./components/principal";

const Muestras = () => {
  return (
    <>
      <React.Fragment>
        <Principal></Principal>
      </React.Fragment>
    </>
  );
};

export default Muestras;
