import {BACKEND} from '../../consts/backend';

const GetTipos = async () => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const resp = await fetch(
    `${BACKEND}/modotransporte`,
    requestOptions
  );
  const sku = await resp.json();

  return sku;
};

export default GetTipos;
