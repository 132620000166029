export const Carrierhistorico = (port, dato) => {
  let aux = [];
  aux = port.filter((element) => element.value === dato);
  return aux.length === 0 ? '-' : aux[0]?.label;
};
export const Carrierhistorico2 = (port, dato) => {
  let aux = [];
  aux = port.filter((element) => element.value === dato);
  return aux.length === 0 ? '-' : aux[0]?.name;
};
export const findcountry = (port, dato) => {
  let aux = [];
  aux = port.filter((element) => element.value === dato);
  return aux.length === 0 ? '-' : aux[0]?.country;
};
export const Currencyhistorico = (port, dato) => {
  let aux = [];
  aux = port.filter((element) => element.id === dato);
  return aux.length === 0 ? '-' : aux[0]?.label;
};
export const CurrencyHistoricoByCode = (port, dato) => {
  let aux = [];
  aux = port.filter((element) => element.value === dato);
  return aux.length === 0 ? '-' : aux[0]?.label;
};

export const tableNumberFormat = (e, modulo) => {
  let aux = String(e).split('.');
  if (aux[1] && modulo) {
    if (aux[1]?.length > 6) {
      return String(e.toFixed(6)).replace('.', ',');
    } else {
      return String(e).replace('.', ',');
    }
  } else {
    return String(e.toFixed(2)).replace('.', ',');
  }
};

export const dollarUSA = (e) => {
  return '$' + e;
};
