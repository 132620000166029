import React, {useEffect, useState} from 'react';

import {
  Card,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from '../../../../components/Component';
import classnames from 'classnames';
import {General} from './components/General';
import {Products} from './components/Products';
import SelectProvider from '../helpers/providerSelects';
import FollowUpExpoGet301CarrierLeg from '../helpers/301.helper';
import {TerminalProvider} from '../Masterdbcomponents/providers/TerminalProvider';

export const NewOrder = () => {
  const [incoterms, setIncoterms] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [custom, setCustom] = useState([]);
  const [ata_agent, setAta_Agent] = useState([]);

  const [terrestre, setTerrestre] = useState([]);
  const [port, setPort] = useState([]);
  const [portGlobal, setPortGlobal] = useState([]);

  const [terminal, setTerminal] = useState([]);

  const [gmid, setGmid] = useState([]);

  const [countries, setCountries] = useState([]);

  const [currencies, setCurrencies] = useState([]);

  const [bank, setBank] = useState([]);
  const [vessel, setVessel] = useState([]);

  const [productEvent, setProductEvent] = useState({
    materials: [],
    tablePacking: [],
    packingData: {
      seal: '',
      container: '',
      pallet: '',
    },
    totalsuma: {
      totalWeightGross: 0,
      totalWeightNet: 0,
      quantity_total: 0,
      finalUnitPrice: 0,
      totalFob: 0,
      totalFobFca: 0,
      pallets: 0,
      freight: 0,
      insurance: 0,
      lastTotal: 0,
      totalIncoterm: 0,
    },
  });
  const [generalEvent, setGeneralEvent] = useState({
    file: '',
    date: '',
    status_id: '',
    order_rush: '',
    po: '',
    shipments: '',
    business_id: '',
    ordering_party: '',
    general_ship_to: '',
    general_notify_1: '',
    general_notify_2: '',
    remarks_free_form_message: '',
    ref_notes: '',
  });

  const [transportEvent, setTransportEvent] = useState({
    aditional_mobility: '',
    ata_agent_id: '',
    inland_trucker: '',
    entry_terminal: '',
    delivery_date: '',
    booking_number: '',
    delivery_to_port_plan_date: '',
    booking_approval_date: '',
    document_cutoff_date: '',
    cutoff_date_at_departure: '',
    documental_cut_off_imo: '',
    vgm_cut_off: '',
    estimated_arrival_date: '', //estimate arrival date
    actual_arrival_date: '', //actual arrival date
    estimated_departure_date: '', //estinate departure date
    actual_discharge: '', //actual discharge
    flight_voyage_number: '',
    expo_vessels_id: '',
    carriers_leg_id: '',
    transport_mode: '',
    incoterm_id: '',
    incoterm_2: '',
    plant_id: '',
    purchase_order: '',
    departure_port_id: '',
    destination_port_id: '',
    shipment_id: '',
  });

  const [customEvent, setCustomEvent] = useState({
    permition_date: '', // permit(date)
    per_export: '', //Export permit
    commission: '', //Comission
    linked_company: '', // linked company 0-1
    bank_id: '', //bank name
    triangulated: '', // Triangulated 0 - 1
    temporary_import: '', // Temporary Import 0 -1
    terms_of_sale_description: '', //payment Term
    custom_transmition_date: '', // Custom Transmission Date
    containers_quantity: '', // container
    currency_id: '', // currency
    purchase_order: '', // PO
    departure_city: '', // customs (origin)
    departure_exit_city: '', // customs (Exit)
    vgm_cut_off: '',
    customs_broker_id: '',
    shipping_id: '',
    cuit: '',
    material: [],
  });

  const [activeIconTab, setActiveIconTab] = useState('1');

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  useEffect(() => {
    FollowUpExpoGet301CarrierLeg().then((response) => {
      let aux = response.map((element) => {
        return {
          label: element.carrier_full,
          value: element.value,
          name: element.label,
          code: element.carrier_code,
        };
      });

      let aux2 = [
        {
          label: 'Select value',
          value: null,
          carrier_code: null,
        },
      ].concat(aux);
      setCarriers(aux2);
    });

    new SelectProvider().getAta().then((response) => {
      let aux = response.map((element) => {
        return {
          value: element.id,
          label: element.ata_name,
        };
      });
      setAta_Agent(aux);
    });

    new SelectProvider().getcurrencies().then((response) => {
      const aux = response.map((element) => {
        return {
          value: element.code,
          label: element.label,
          id: element.value,
        };
      });
      setCurrencies(aux);
    });

    new TerminalProvider().getTerminal().then((result) => {
      let aux = result.map((element) => {
        return {
          value: element.id,
          label: element.terminal_name,
        };
      });
      setTerminal(aux);
    });

    new SelectProvider().getGmid().then((response) => {
      let aux = [];
      response.forEach((element) => {
        aux.push({
          value: element.code,
          label: element.code,
          material_origin_id: element.iso,
          ncm: element.ncm,
          packing: element.packaging,
        });
      });
      setGmid(aux);
    });

    new SelectProvider().getdowport().then((portResponse) => {
      let aux = [];
      let aux1 = [];
      let aux2 = portResponse.map((element) => {
        return {
          value: element.id,
          label: element.dp_name,
          name: element.dp_name,
          code: element.dp_code,
          country: element.country_name,
        };
      });

      new SelectProvider().getbank().then((result) => {
        const aux = result.map((element) => {
          return {
            value: element.id,
            label: element.name,
          };
        });
        setBank(aux);
      });

      new SelectProvider()
        .getvessels()
        .then((result) => {
          const aux = [];
          result.forEach((element) => {
            aux.push({
              value: element.id,
              label: element.name,
              vessel_code: element.iso,
              voyage: element.voyage,
            });
          });
          setVessel(aux);
        })
        .catch((err) => console.log(err));

      new SelectProvider().getcountries().then((response) => {
        const aux = [];
        response.forEach((element) => {
          aux.push({
            value: element.iso,
            label: element.name,
          });
        });
        setCountries(aux);
      });

      portResponse.forEach((element) => {
        if (element.dp_type === 'Terrestre') {
          aux.push({
            value: element.id,
            label: element.dp_name,
            name: element.dp_name,
            code: element.dp_code,
            country: element.country_name,
          });
        }
      });
      portResponse.forEach((element) => {
        if (element.dp_type === 'Marítima') {
          aux1.push({
            value: element.id,
            label: element.dp_name,
            name: element.dp_name,
            code: element.dp_code,
            country: element.country_name,
          });
        }
      });
      setTerrestre(aux);
      setPortGlobal(aux2);
      setPort(aux1);
    });

    new SelectProvider()
      .getincoterms()
      .then((result) => {
        const aux = [];
        result.forEach((element) => {
          aux.push({
            value: element.incoterm_id,
            label: element.incoterm_code,
          });
        });
        setIncoterms(aux);
      })
      .catch((err) => console.log(err));

    new SelectProvider().getcustom().then((result) => {
      const aux = result.map((element) => {
        return {
          value: element.id,
          label: element.descripcion,
          codigo: element.descripcion,
        };
      });
      setCustom(aux);
    });
  }, []);
  return (
    <>
      <Container fluid>
        <BlockHead size="sm">
          <br />
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                New Order
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({
                          active: activeIconTab === '1',
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab('1');
                        }}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>General</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({
                          active: activeIconTab === '2',
                        })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab('2');
                        }}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>Products</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </ul>
                <TabContent activeTab={activeIconTab}>
                  <TabContent activeTab={activeIconTab}>
                    <TabPane tabId="1">
                      <General
                        generalEvent={generalEvent}
                        setGeneralEvent={setGeneralEvent}
                        productEvent={productEvent}
                        setProductEvent={setProductEvent}
                        transportEvent={transportEvent}
                        setTransportEvent={setTransportEvent}
                        customEvent={customEvent}
                        setCustomEvent={setCustomEvent}
                        incoterms={incoterms}
                        carriers={carriers}
                        custom={custom}
                        ata={ata_agent}
                        terrestre={terrestre}
                        port={port}
                        terminal={terminal}
                        currencies={currencies}
                        bank={bank}
                        countries={countries}
                        vessel={vessel}
                      />
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={activeIconTab}>
                    <TabPane tabId="2">
                      <Products
                        productEvent={productEvent}
                        setProductEvent={setProductEvent}
                        gmid={gmid}
                        countries={countries}
                        currencies={currencies}
                      />
                    </TabPane>
                  </TabContent>
                </TabContent>
              </div>
            </div>
          </Card>
        </Block>
      </Container>
    </>
  );
};
