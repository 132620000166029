import {Fragment, useEffect, useState} from 'react';
import {
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
} from 'reactstrap';
import {Block} from '../../../../../components/Component';
import CustomSelect from '../../customselect';
import ReactFlagsSelect from 'react-flags-select';
import {Products} from './Products';
import {
  Carrierhistorico,
  dollarUSA,
  tableNumberFormat,
} from '../../../../helpers/FoundValue';
import {nanoid} from 'nanoid';
import {NewMakeReport} from './../helper/new_general_report';

export const General = ({
  generalEvent,
  setGeneralEvent,
  productEvent,
  setProductEvent,
  transportEvent,
  setTransportEvent,
  customEvent,
  setCustomEvent,
  incoterms,
  carriers,
  custom,
  ata,
  terrestre,
  port,
  terminal,
  currencies,
  bank,
  countries,
  vessel,
}) => {
  useEffect(() => {}, []);

  //select section

  const business = [
    {value: 31, label: 'Plastics'},
    {value: 32, label: 'Chemicals'},
    {value: 33, label: 'Hydrocarbon'},
  ];

  const booleans = [
    {
      value: 0,
      label: 'No',
    },
    {
      value: 1,
      label: 'Yes',
    },
  ];
  const mot = [
    {value: 7, label: 'Sea'},
    {value: 11, label: 'AIR'},
    {value: 9, label: 'Road'},
  ];

  const status = [
    {value: 7, label: 'New'},
    {value: 14, label: 'Accepted by FF'}, //
    {value: 15, label: 'In Transit '}, //
    {value: 9, label: 'Arrived'}, //
    {value: 10, label: 'Canceled'}, //
  ];

  const onChange = (e) => {
    let id = e.target.id;
    switch (e.target.name) {
      case 'general':
        if (e.target.id === 'order_rush') {
          setGeneralEvent((element) => ({
            ...element,
            [e.target.id]: e.target.checked ? 1 : 0,
          }));
        } else {
          setGeneralEvent((element) => ({
            ...element,
            [e.target.id]: e.target.value,
          }));
        }
        break;
      case 'transport':
        setTransportEvent((element) => ({
          ...element,
          [e.target.id]: e.target.value,
        }));
        break;
      case 'custom':
        setCustomEvent((element) => ({
          ...element,
          [e.target.id]: e.target.value,
        }));
        break;
      case 'product':
        let aux = productEvent.materials.map((element) => {
          if (id === 'containers_quantity') {
            return {
              ...element,
              [id]: parseInt(e.value),
            };
          } else {
            return {
              ...element,
              [id]: e.value,
            };
          }
        });
        setProductEvent((element) => ({
          ...element,
          materials: aux,
        }));
        break;
      default:
        break;
    }
  };
  const onChangeSelect = (e, id, index = -1, name) => {
    switch (name) {
      case 'transport':
        setTransportEvent((element) => ({
          ...element,
          [id]: e.value,
        }));
        break;
      case 'custom':
        setCustomEvent((element) => ({
          ...element,
          [id]: e.value,
        }));
        break;
      case 'general':
        setGeneralEvent((element) => ({
          ...element,
          [id]: e.value,
        }));
        break;
      case 'product':
        let aux = productEvent.materials.map((element) => {
          if (id === 'containers_quantity') {
            return {
              ...element,
              [id]: parseInt(e.target.value),
            };
          } else {
            return {
              ...element,
              [id]: e.value,
            };
          }
        });
        setProductEvent((element) => ({
          ...element,
          materials: aux,
        }));
        break;
      default:
        break;
    }
  };
  const onChangeFlag = (e) => {
    setGeneralEvent((element) => ({
      ...element,
      flag: e,
    }));
  };

  return (
    <Fragment>
      {' '}
      <Container>
        <Block>
          <div className="nk-modal-head mb-3">
            <h5>Order Information</h5>
          </div>
          <Row className="gy-3">
            <Col md="12">
              <div className={`accordion-head`}>
                <div className="accordion-item">
                  <div className="accordion-inner">
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-6">
                        <FormGroup className="form-group">
                          <Label className="form-label">File</Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="text"
                              id="file"
                              name="general"
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-6">
                        <FormGroup className="form-group">
                          <Label className="form-label">Date</Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="date"
                              id="date"
                              name="general"
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label className="form-label">Status</Label>
                          <div className="form-control-wrap">
                            <CustomSelect
                              id={'status_id'}
                              onChange={onChangeSelect}
                              optionsSelect={status}
                              placeholder={'Select Status'}
                              name={'general'}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="orderRush"
                          >
                            Order Rush
                          </Label>
                          <FormGroup>
                            <Input
                              bsSize="sm"
                              className="form-control"
                              type="checkbox"
                              id="order_rush"
                              onClick={onChange}
                              name="general"
                            />
                          </FormGroup>
                        </FormGroup>
                      </Col> */}
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-6">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="order"
                          >
                            Order
                          </Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="text"
                              id="po"
                              name="general"
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-6">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="shipments"
                          >
                            Shipments
                          </Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="text"
                              id="shipments"
                              name="general"
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* 
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="business"
                          >
                            Business
                          </Label>
                          <CustomSelect
                            id={'business_id'}
                            name={'general'}
                            onChange={onChangeSelect}
                            optionsSelect={business}
                            placeholder={'Select business'}
                          />
                        </FormGroup>
                      </Col> */}
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="orderingParty"
                          >
                            Ordering Party
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              className="form-control"
                              id="ordering_party"
                              name="general"
                              placeholder={'Add Ship to'}
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="shipTo"
                          >
                            Ship to
                          </Label>

                          <div className="form-control-wrap">
                            <textarea
                              className="form-control"
                              id="general_ship_to"
                              name="general"
                              placeholder={'Add Ship to'}
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="notify"
                          >
                            Notify
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              className="form-control"
                              id="general_notify_1"
                              name="general"
                              placeholder={'add Notify'}
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="notify2"
                          >
                            Notify 2
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              className="form-control"
                              id="general_notify_2"
                              name="general"
                              placeholder={'add Notify'}
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-inner">
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label className="form-label" htmlFor="mod">
                            Mot
                          </Label>
                          <div className="form-control-wrap">
                            <CustomSelect
                              id="transport_mode"
                              name="transport"
                              onChange={onChangeSelect}
                              optionsSelect={mot}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="incoterm_id"
                          >
                            Incoterm
                          </Label>
                          <div className="form-control-wrap">
                            <CustomSelect
                              id="incoterm_id"
                              name="transport"
                              optionsSelect={incoterms}
                              onChange={onChangeSelect}
                              placeholder={'Select Incoterm'}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="incoterm_id"
                          >
                            Incoterm Location
                          </Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="text"
                              id="incoterm_2"
                              name="transport"
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="carrier"
                          >
                            Carrier
                          </Label>
                          <CustomSelect
                            optionsSelect={carriers}
                            onChange={onChangeSelect}
                            placeholder={'Select a country'}
                            id={'carriers_leg_id'}
                            name={'transport'}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {transportEvent.transport_mode === 7 ? (
                      <Row className="mb-auto p-2">
                        <Col className="col-sm-3">
                          <FormGroup className="form-group">
                            <Label
                              className="form-label"
                              htmlFor="booking"
                            >
                              Booking
                            </Label>
                            <div className="form-control-wrap">
                              <Input
                                className="form-control"
                                type="text"
                                id="booking_number"
                                name="transport"
                                onChange={onChange}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="col-sm-3">
                          <FormGroup className="form-group">
                            <Label
                              className="form-label"
                              htmlFor="ship"
                            >
                              Vessel
                            </Label>
                            <div className="form-control-wrap">
                              <CustomSelect
                                id={'expo_vessels_id'}
                                name={'transport'}
                                optionsSelect={vessel}
                                placeholder={'Select a Vessel'}
                                onChange={onChangeSelect}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="col-sm-3">
                          <FormGroup className="form-group">
                            <Label
                              className="form-label"
                              htmlFor="flag"
                            >
                              Flag
                            </Label>
                            <div className="form-control-wrap">
                              <ReactFlagsSelect
                                selectedSize={9}
                                optionsSize={12}
                                onSelect={onChangeFlag}
                                selected={generalEvent.flag}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="col-sm-3">
                          <FormGroup className="form-group">
                            <Label
                              className="form-label"
                              htmlFor="terminal"
                            >
                              Terminal
                            </Label>
                            <div className="form-control-wrap">
                              <CustomSelect
                                id="entry_terminal"
                                name="transport"
                                placeholder={'Select a Terminal'}
                                onChange={onChangeSelect}
                                optionsSelect={terminal}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}

                    {transportEvent.transport_mode === 7 ? (
                      <Row className="mb-auto p-2">
                        <Col className="col-sm-3">
                          <FormGroup className="form-group">
                            <Label
                              className="form-label"
                              htmlFor="documentaryCut"
                            >
                              Documental Cut-off
                            </Label>
                            <div className="form-control-wrap">
                              <Input
                                className="form-control"
                                type="date"
                                id="document_cutoff_date"
                                name="transport"
                                onChange={onChange}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="col-sm-3">
                          <FormGroup className="form-group">
                            <Label
                              className="form-label"
                              htmlFor="physicalCut"
                            >
                              Physical Cut-off
                            </Label>
                            <div className="form-control-wrap">
                              <Input
                                className="form-control"
                                type="date"
                                id="cutoff_date_at_departure"
                                name="transport"
                                onChange={onChange}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="col-sm-3">
                          <FormGroup className="form-group">
                            <Label
                              className="form-label"
                              htmlFor="etd"
                            >
                              ETD
                            </Label>
                            <div className="form-control-wrap">
                              <Input
                                className="form-control"
                                type="date"
                                id="estimated_departure_date"
                                name="transport"
                                onChange={onChange}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col className="col-sm-3">
                          <FormGroup className="form-group">
                            <Label
                              className="form-label"
                              htmlFor="amountContainer"
                            >
                              ETA
                            </Label>
                            <div className="form-control-wrap">
                              <Input
                                className="form-control"
                                type="date"
                                id="estimated_arrival_date"
                                name="transport"
                                onChange={onChange}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}

                    <Row className="mb-auto p-2">
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="plant"
                          >
                            Plant (Origin)
                          </Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="text"
                              id="plant_id"
                              name="transport"
                              onChange={onChange} // preguntar cambiar por a select?
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {transportEvent.transport_mode == '7' ? (
                        <>
                          <Col className="col-sm-3">
                            <FormGroup className="form-group">
                              <Label
                                className="form-label"
                                htmlFor="portLoading"
                              >
                                Departure Port
                              </Label>
                              <div>
                                <CustomSelect
                                  optionsSelect={port}
                                  id="departure_port_id"
                                  name="transport"
                                  placeholder={`Select a Departure ${
                                    transportEvent.transport_mode ==
                                    '7'
                                      ? ' Port'
                                      : ''
                                  }`}
                                  onChange={onChangeSelect}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col className="col-sm-3">
                            <FormGroup className="form-group">
                              <Label
                                className="form-label"
                                htmlFor="portDestiny"
                              >
                                Destination Port
                              </Label>
                              <CustomSelect
                                optionsSelect={port}
                                id="destination_port_id"
                                name="transport"
                                placeholder={`Select a Destination Port`}
                                onChange={onChangeSelect}
                              />
                            </FormGroup>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col className="col-sm-3">
                            <FormGroup className="form-group">
                              <Label
                                className="form-label"
                                htmlFor="portLoading"
                              >
                                Departure
                              </Label>
                              <div>
                                <CustomSelect
                                  optionsSelect={terrestre}
                                  id="departure_port_id"
                                  name="transport"
                                  placeholder={`Select a Departure`}
                                  onChange={onChangeSelect}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col className="col-sm-3">
                            <FormGroup className="form-group">
                              <Label
                                className="form-label"
                                htmlFor="portDestiny"
                              >
                                Destination
                              </Label>
                              <CustomSelect
                                optionsSelect={terrestre}
                                id="destination_port_id"
                                name="transport"
                                placeholder={`Select a Destination`}
                                onChange={onChangeSelect}
                              />
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </Row>

                    <Row className="mb-auto p-2">
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="customsRegistration"
                          >
                            Customs (Origin)
                          </Label>
                          <CustomSelect
                            id={'customs_origin_id'}
                            name={'custom'}
                            onChange={onChangeSelect}
                            placeholder={'Select city'}
                            optionsSelect={custom}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="customsExit"
                          >
                            Customs (Exit)
                          </Label>
                          <CustomSelect
                            id={'customs_destination_id'}
                            name={'custom'}
                            onChange={onChangeSelect}
                            placeholder={'Select city'}
                            optionsSelect={custom}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="ataAndCuit"
                          >
                            ATA (Agente)
                          </Label>
                          <div className="form-control-wrap">
                            <CustomSelect
                              optionsSelect={ata}
                              onChange={onChangeSelect}
                              placeholder={`Select a Destination Ata Agent`}
                              id={'ata_agent_id'}
                              name={'transport'}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-3"></Col>
                    </Row>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-inner">
                    <Row className="mb-auto p-2">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th style={{width: '2rem'}}>ITEM</th>
                            <th style={{width: '6rem'}}>QUANTITY</th>
                            <th style={{width: '6rem'}}>ORIGIN</th>
                            <th style={{width: '6rem'}}>PACKAGING</th>
                            <th style={{width: '6rem'}}>PALLET</th>
                            <th style={{width: '6rem'}}>GMID</th>
                            <th style={{width: '6rem'}}>NCM</th>
                            <th style={{width: '12rem'}}>
                              DESCRIPTION
                            </th>
                            <th style={{width: '2rem'}}>
                              GROSS WEIGHT
                            </th>
                            <th style={{width: '2rem'}}>
                              NET WEIGHT
                            </th>
                            <th style={{width: '6rem'}}>
                              UNIT NET PRICE
                            </th>
                            <th style={{width: '6rem'}}>
                              TOTAL NET PRICE
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productEvent.materials.map(
                            (item, index) => (
                              <tr key={nanoid()}>
                                <td>{index + 1}</td>
                                <td>
                                  {parseFloat(item?.quantity_uom)}
                                </td>
                                <td>{item?.material_origin}</td>
                                <td>{item?.packaging_form_code}</td>
                                <td>
                                  {parseInt(item?.materials_pallet)}
                                </td>
                                <td>{item?.code}</td>
                                <td>{item?.ncm_code}</td>
                                <td>{item?.name}</td>
                                <td>
                                  {tableNumberFormat(
                                    parseFloat(item?.weight_gross_uom)
                                  )}
                                </td>
                                <td>
                                  {tableNumberFormat(
                                    parseFloat(item?.weight_net_uom)
                                  )}
                                </td>
                                <td>
                                  {dollarUSA(
                                    tableNumberFormat(
                                      parseFloat(item?.unit_price),
                                      'tnt'
                                    )
                                  )}
                                </td>
                                <td>
                                  {dollarUSA(
                                    parseFloat(item?.unit_price) *
                                      parseFloat(item?.quantity_uom)
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td></td>
                            <td>
                              <h6 style={{fontSize: '.9rem'}}>
                                Total Quantity
                              </h6>
                              {parseFloat(
                                productEvent.totalsuma.quantity_total
                              )}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <h6>weight</h6>
                              {tableNumberFormat(
                                parseFloat(
                                  productEvent.totalsuma
                                    .totalWeightGross
                                )
                              )}
                            </td>
                            <td>
                              <h6>net</h6>
                              {tableNumberFormat(
                                parseFloat(
                                  productEvent.totalsuma
                                    .totalWeightNet
                                )
                              )}
                            </td>
                            <td style={{fontSize: 'bold'}}>
                              Total Fob/Fca:
                            </td>
                            <td>
                              {dollarUSA(
                                tableNumberFormat(
                                  parseFloat(
                                    productEvent.totalsuma.totalFobFca
                                  )
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={10}></td>
                            <td style={{fontSize: 'bold'}}>
                              Total Freight:
                            </td>
                            <td>
                              {dollarUSA(
                                tableNumberFormat(
                                  parseFloat(
                                    productEvent.totalsuma.freight
                                  )
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={10}></td>
                            <td style={{fontSize: 'bold'}}>
                              Total Insurance:
                            </td>
                            <td>
                              {dollarUSA(
                                tableNumberFormat(
                                  parseFloat(
                                    productEvent.totalsuma.insurance
                                  )
                                )
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={10}></td>
                            <td style={{fontSize: 'bold'}}>
                              Total{' '}
                              {`${Carrierhistorico(
                                incoterms,
                                transportEvent?.incoterm_id
                              )}`}
                              :
                            </td>
                            <td>
                              {dollarUSA(
                                tableNumberFormat(
                                  parseFloat(
                                    productEvent.totalsuma.lastTotal
                                  )
                                )
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-inner">
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-4">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="commissions"
                          >
                            Commissions
                          </Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="number"
                              id="commission"
                              name="custom"
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-4">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="lumps"
                          >
                            Packages
                          </Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="text"
                              id="pallets"
                              name="suma"
                              value={productEvent.totalsuma.pallets}
                              disabled //cambiar por suma de pales
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-4">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="container"
                          >
                            Commissions Value
                          </Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="text"
                              value={tableNumberFormat(
                                parseFloat(
                                  productEvent.totalsuma.totalFobFca
                                ) *
                                  (parseFloat(
                                    customEvent.commission
                                  ) /
                                    100) || 0
                              )}
                              disabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-4">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="container"
                          >
                            Equipment
                          </Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="text"
                              id="containers_quantity"
                              name="product"
                              onChange={(e) =>
                                onChangeSelect(
                                  e,
                                  e.target.id,
                                  -1,
                                  e.target.name
                                )
                              }
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-4">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="triangulated"
                          >
                            Triangulated
                          </Label>
                          <CustomSelect
                            optionsSelect={booleans}
                            onChange={onChangeSelect}
                            id={'triangulated'}
                            name={'custom'}
                            placeholder={'Select an option'}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-4">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="relatedCompany"
                          >
                            Linked Company
                          </Label>
                          <CustomSelect
                            optionsSelect={booleans}
                            onChange={onChangeSelect}
                            id={'linked_company'}
                            name={'custom'}
                            placeholder={'Select an option'}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-inner">
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-4">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="bank"
                          >
                            Bank
                          </Label>
                          <div className="form-control-wrap">
                            <CustomSelect
                              id="bank_id"
                              name="custom"
                              onChange={onChangeSelect}
                              optionsSelect={bank}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-4">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="coin"
                          >
                            Currency
                          </Label>
                          <div className="form-control-wrap">
                            <CustomSelect
                              optionsSelect={currencies}
                              id="currency_id"
                              name="custom"
                              onChange={onChangeSelect}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-4">
                        <FormGroup className="form-group">
                          <Label
                            className="form-label"
                            htmlFor="paymentTerm"
                          >
                            Payment Term
                          </Label>
                          <div className="form-control-wrap">
                            <Input
                              className="form-control"
                              type="text"
                              id="terms_of_sale_description"
                              name="custom"
                              onChange={onChange}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col className="col-sm-12">
                        <FormGroup className="form-group">
                          <Label className="form-label">
                            Additional
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              className="form-control"
                              id="remarks_free_form_message"
                              name="general"
                              onChange={onChange}
                              placeholder={'Add aditional comment'}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-inner">
                    <Row>
                      <NewMakeReport
                        productEvent={productEvent}
                        transportEvent={transportEvent}
                        carriers={carriers}
                        vessel={vessel}
                        countries={countries}
                        port={port}
                        custom={custom}
                        customsEvent={customEvent}
                        ata={ata}
                        bank={bank}
                        currencies={currencies}
                        generalEvent={generalEvent}
                        incoterms={incoterms}
                        terminal={terminal}
                      />
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Block>
      </Container>{' '}
    </Fragment>
  );
};
