import {apiKey} from '../../followUp/Components/helpers/keys.helper';
import {BACKEND} from '../../../consts/backend.js';

const auxiliar = async (data) => {
  let requestOptions = {
    method: 'PATCH',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: apiKey,
    },
    body: JSON.stringify(data),
  };
  //console.log('json rta:',JSON.stringify(data));
  const resp = await fetch(
    `${BACKEND}/expo/excel-integration`,
    requestOptions
  );

  const sku = await resp.json();
  return sku;
};
function groupJsonElements(jsonArray, groupSize) {
  let groupedJson = [];
  for (let i = 0; i < jsonArray.length; i += groupSize) {
      let group = jsonArray.slice(i, i + groupSize);
      groupedJson.push(group);
  }
  return groupedJson;
}

const excelIntegration = async (datos) => {
  const data = groupJsonElements(datos, 45);
  console.log('data:', data);
  let response = {};

  try {
    await Promise.all(data.map(async (element) => {
      await auxiliar(element);
    }));

    response = {
      "error": "no hubo errores, se proceso todo correctamente",
      'data': {'state': true},
      'grupos': data
    };
  } catch (error) {
    response = {
      "error": error.message || "Hubo un error al procesar los datos",
      'data': {'state': false},
      'grupos': data
    };
  }

  return response;
}

export default excelIntegration;
