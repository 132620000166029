import React, {useState} from 'react';
import {Card, Container} from 'reactstrap';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../../../components/Component';
import {GiHamburgerMenu} from 'react-icons/gi';
import {useDrag, useDrop} from 'react-dnd';
import classnames from 'classnames';
import Content from '../../../layout/content/Content';
import {Row, Col, Table} from 'react-bootstrap';
import Head from '../../../layout/head/Head';
import {nanoid} from 'nanoid';
import {SellerFilesTest} from './SellerFilesTest';
import CustomSelect from '../../followUp/Components/customselect';
const AVAILABLE_STATE = 'available';
const SELECTED_STATE = 'selected';

export const DashboardMacroEdit = () => {
  const [atribute, setAtribute] = useState('');
  const [data, setData] = useState([]);
  const [label, setlabel] = useState([]);

  const headersNames = [
    {label: 'Status', prop: 'status_id'},
    {label: 'Order Rush', prop: 'order_rush'},
    // {label: 'Additional', prop: 'remarks_free_form_message'},
    {label: 'container Quantity', prop: 'containers_quantity'},
    {label: 'Currency', prop: 'exchange_rate_currency_code'},
    {label: 'Transport Method', prop: 'transport_mode'},
    {label: 'Incoterm', prop: 'incoterm_id'},
    {label: 'Document Packet Sent', prop: 'document_packet_date'},
    {label: 'Incoterm (Location)', prop: 'incoterm_2'},
    {label: 'Departure', prop: 'departure_port_id'},
    {label: 'Destination', prop: 'destination_port_id'},
    {label: 'ATA (Agente)', prop: 'ata_agent_id'},
    {label: 'Inland Transport', prop: 'inland_trucker'},
    {label: 'Terminal', prop: 'entry_terminal'},
    {label: 'Carrier', prop: 'carriers_leg_id'},
    {label: 'Booking Request', prop: 'delivery_date'},
    {label: 'Booking', prop: 'booking_number'},
    {label: 'Date Booking', prop: 'delivery_to_port_plan_date'},
    {
      label: 'Confirmation Booking',
      prop: 'booking_approval_date',
    },
    {label: 'Documental Cut Off', prop: 'document_cutoff_date'},
    {
      label: 'Physical Cut Off',
      prop: 'cutoff_date_at_departure',
    },
    {
      label: 'Documental Cut Off (IMO)',
      prop: 'documental_cut_off_imo',
    },
    {label: 'VGM Cut Off', prop: 'vgm_cut_off'},
    {label: 'ETD', prop: 'estimated_departure_date'},
    {
      label: 'ATD',
      prop: 'actual_discharge',
    },
    {label: 'ETA', prop: 'estimated_arrival_date'},
    {label: 'ATA', prop: 'actual_arrival_date'},
    {label: 'Vessel', prop: 'expo_vessels_id'},
    {label: 'Voyage', prop: 'flight_voyage_number'},
    // {label: 'Flag', prop: 'flag'},
    {label: 'Customs (Origin)', prop: 'customs_origin_id'},
    {label: 'Customs (Exit)', prop: 'customs_destination_id'},
    {label: 'Permit (Date)', prop: 'permition_date'},
    {label: 'Export Permit', prop: 'per_export'},
    {label: 'Comission', prop: 'commission'},
    {label: 'Linked Company', prop: 'linked_company'},
    {
      label: 'Bank',
      prop: 'bank_id',
    },
    {label: 'Currency (Custom)', prop: 'currency_id'},
    {label: 'Triangulated', prop: 'triangulated'},
    {label: 'Nacionalizated', prop: 'nationalized'},
    {label: 'Temporary Import', prop: 'temporary_import'},
    // {label: 'Payment Term', prop: 'terms_of_sale_description'},
    {
      label: 'Custom Transmission Date',
      prop: 'custom_transmition_date',
    },
    {label: 'Custom Broker', prop: 'customs_broker_id'},
    // {label: 'cuit', prop: 'cuit'},
    {
      label: 'Document Invoice',
      prop: 'invoice',
      extra: 'Document Date',
    },
    //{label: 'Document N° Invoice', prop: 'file_new_name'},
    {
      label: 'Document PackingList',
      prop: 'packing_list',
      extra: 'Document Date',
    },
    //{label: 'Document N° PackingList', prop: 'file_new_name'},
    {
      label: 'Document COO/COD',
      prop: 'COO/COD',
      extra: 'Document Date',
    },
    //{label: 'Document N° COO/COD', prop: 'file_new_name'},
    {
      label: 'Document Export Permit',
      prop: 'export_permit',
      extra: 'Document Date',
    },
    //{label: 'Document N° Export Permit', prop: 'file_new_name'},
    {
      label: 'Document Bill of Lading',
      prop: 'bill_of_landing',
      extra: 'Document Date',
    },
    //{label: 'Document N° Bill of Lading', prop: 'file_new_name'},
    {
      label: 'Document CRT',
      prop: 'CRT',
      extra: 'Document Date',
    },
    //{label: 'Document N° CRT', prop: 'file_new_name'},
    {
      label: 'Document Airwaybill',
      prop: 'airwaybill',
      extra: 'Document Date',
    },
    //{label: 'Document N° Airwaybill', prop: 'file_new_name'},
    {
      label: 'Document Export License',
      prop: 'export_license',
      extra: 'Document Date',
    },
    //{label: 'Document N° Export License', prop: 'file_new_name'},
    {
      label: 'Document MSDS',
      prop: 'MSDS',
      extra: 'Document Date',
    },
    //{label: 'Document N° MSDS', prop: 'file_new_name'},
    {
      label: 'Document COA',
      prop: 'COA',
      extra: 'Document Date',
    },
    //{label: 'Document N° COA', prop: 'file_new_name'},
    // {label: 'First leg mode', prop: 'first_leg_mode_id'},
    // {label: 'First Office Number', prop: 'first_office_number'},
    // {label: 'First Motor Carrier', prop: 'first_motor_carrier'},
    // {label: 'Second leg mode', prop: 'second_leg_mode_id'},
    // {label: 'Second Office Number', prop: 'second_office_number'},
    // {label: 'Second Motor Carrier', prop: 'second_motor_carrier'},
    // {
    //   label: 'Portal Terminal Transpotation',
    //   prop: 'port_terminal_id',
    // },
    // {label: 'Text 501', prop: 'remarks'},
    // {label: 'Text 502', prop: 'text502'}
  ];

  const onChange = (e) => {
    if (e.label.substr(0, 8) === 'Document' && e.label != 'Document Packet Sent') {
      setlabel([e.label, e.extra]);
    } else {
      setlabel([e.label]);
    }
    setAtribute(e);
    setData([]);
  };
  const options = headersNames.map((header, index) => {
    return {
      prop: header.prop,
      label: header.label,
      index: index,
      isSelected: false,
    };
  });
  const [availableHeadersList, setAvailableHeadersList] =
    useState(options);
  const [{isOver}, addToSelected] = useDrop({
    accept: AVAILABLE_STATE,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{isOver: isAvailableOver}, removeFromSelected] = useDrop({
    accept: SELECTED_STATE,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const moveColumns = (itemSelected) => {
    const $availableHeadersList = Object.create(availableHeadersList);
    const itemToReplace = $availableHeadersList.find(
      (option) => option.index === itemSelected.index
    );
    if (itemToReplace) {
      itemToReplace.isSelected = !itemSelected.isSelected;
      $availableHeadersList.splice(
        $availableHeadersList.indexOf(itemToReplace),
        1,
        itemToReplace
      );
      setAvailableHeadersList($availableHeadersList);
    }
  };

  const dragHoverTeamBG = isOver ? 'bg-light' : 'bg-white';
  const dragHoverPlayerBG = isAvailableOver ? 'bg-light' : 'bg-white';
  return (
    <>
      <Content>
        <Head title="MacroEdit - SSB SYSTEM"></Head>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="text-ssbnaranja" tag="h2" page>
                MacroEdit - SSB SYSTEM
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="card-stretch">
            <div className="card-inner border-primary">
              <h5 className="text-ssbazul">
                Select the columns to Edit
              </h5>

              <Row>
                {/* <Col lg="6" md="12">
                  <h6>Available columns</h6>
                  <div
                    style={{
                      height: '15rem',
                      overflowY: 'scroll',
                      display: 'block',
                    }}
                    className={classnames(
                      'shadow-none p-1 mb-5 rounded border',
                      dragHoverPlayerBG
                    )}
                  >
                    <ul
                      className="list-group"
                      ref={removeFromSelected}
                      style={{height: '50rem'}}
                    >
                      {availableHeadersList?.map(
                        (item, indexAvailable) => (
                          <Header
                            className={classnames(
                              item.isSelected &&
                                'sr-only visually-hidden'
                            )}
                            item={{...item}}
                            key={nanoid()}
                            onDropAvailable={moveColumns}
                            headerState={AVAILABLE_STATE}
                          />
                        )
                      )}
                    </ul>
                  </div>
                </Col>
                <Col lg="6" md="12">
                  <h6>Selected columns</h6>
                  <div
                    style={{
                      height: '15rem',
                      overflowY: 'scroll',
                      display: 'block',
                    }}
                    className={classnames(
                      'shadow-none p-1 mb-5 rounded border',
                      dragHoverTeamBG
                    )}
                  >
                    <ul
                      className="list-group"
                      ref={addToSelected}
                      style={{height: '50rem'}}
                    >
                      {availableHeadersList.map(
                        (item, indexSelected) => (
                          <Header
                            className={classnames(
                              !item.isSelected &&
                                'sr-only visually-hidden'
                            )}
                            item={item}
                            key={nanoid()}
                            onDropAvailable={moveColumns}
                            headerState={SELECTED_STATE}
                          />
                        )
                      )}
                    </ul>
                  </div>
                </Col> */}
                <Col lg="6" md="12">
                  <CustomSelect
                    optionsSelect={headersNames}
                    onChange={onChange}
                  />
                </Col>
                <Col lg="6" md="12">
                  {atribute != '' ? (
                    <SellerFilesTest
                      setData={setData}
                      data={data}
                      atribute={atribute}
                    />
                  ) : null}
                </Col>
              </Row>
              <Row></Row>
            </div>
            <div
              style={{
                height: '40rem',
                overflowY: 'auto',
                display: 'block',
              }}
            >
              {data.length != 0 ? (
                <Table
                  className="table"
                  id="reportsTable"
                  striped
                  bordered
                  hover
                  size="sm"
                >
                  <thead>
                    <tr>
                      <th>Po Number</th>
                      {label.map((element) => {
                        return <th key={nanoid()}>{element}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((element) => {
                      if (
                        atribute.label.substr(0, 8) === 'Document' && atribute.label != 'Document Packet Sent'
                      ) {
                        return (
                          <tr key={nanoid()}>
                            <td>{element.data.po}</td>
                            <td>{element.data.value}</td>
                            <td>{element.data.date}</td>
                          </tr>
                        );
                      }
                      return (
                        <tr key={nanoid()}>
                          <td>{element.data.po}</td>
                          <td>{element.data.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : null}
            </div>
          </div>
        </Block>
      </Content>
    </>
  );
};

const Header = (props) => {
  const {className, onDropAvailable, headerState, item} = props;

  const [{isDragging}, drag] = useDrag(() => ({
    type: headerState,
    item: () => ({...item}),
    end: (data, monitor) => {
      const dropResult = monitor.getDropResult();
      if (data && dropResult) {
        onDropAvailable(data);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <>
      <li
        className={classnames(
          'list-group-item p-1 border',
          className
        )}
        ref={drag}
        style={{cursor: 'pointer'}}
      >
        <span>
          <GiHamburgerMenu className="mr-2" />
          {item.label}
        </span>
      </li>
    </>
  );
};
