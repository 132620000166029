import {Fragment, useState} from 'react';
import {
  Button,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Table,
  Col,
  Row,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import {Block, Icon} from '../../components/Component';
import {nanoid} from 'nanoid';
import {ModalUser} from './ModalUser';

export const UserManagementBoard = ({users, roles, enterprise}) => {
  const [selectUser, setSelectUser] = useState({
    avatar: '',
    email: '',
    conEmail: '',
    id: '',
    first_name: '',
    last_name: '',
    rol: '',
    password: '',
    conPassword: '',
    enterprise: [6],
  });

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setSelectUser({
      avatar: '',
      email: '',
      conEmail: '',
      id: '',
      first_name: '',
      last_name: '',
      rol: '',
      password: '',
      conPassword: '',
      enterprise: [6],
    });
    setShow(false);
  };

  return (
    <Fragment>
      <Container>
        <Block>
          <Row>
            <Col
              lg="12"
              className="pb-3"
              style={{
                height: '40rem',
                overflowX: 'auto',
              }}
            >
              <Table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap">
                      Image
                    </th>
                    <th scope="col" className="text-nowrap">
                      First Name
                    </th>
                    <th scope="col" className="text-nowrap">
                      Last Name
                    </th>
                    <th scope="col" className="text-nowrap">
                      Email
                    </th>
                    <th scope="col" className="text-nowrap">
                      Enterprise
                    </th>
                    <th scope="col" className="text-nowrap">
                      Accion
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((element) => (
                    <tr key={nanoid()}>
                      <td scope="col" className="text-nowrap">
                        <img
                          src={element.avatar}
                          alt="Avatar"
                          width={'10%'}
                          style={{
                            borderRadius: '50%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '1rem',
                          }}
                        ></img>
                      </td>
                      <td scope="col" className="text-nowrap">
                        {element.first_name}
                      </td>
                      <td scope="col" className="text-nowrap">
                        {element.last_name}
                      </td>
                      <td scope="col" className="text-nowrap">
                        {element.email}
                      </td>
                      <td scope="col" className="text-nowrap">
                        Test Enterprise
                      </td>
                      <td>
                        <Row>
                          <a
                            href="#"
                            onClick={(e) => {
                              setSelectUser({
                                avatar: element.avatar,
                                email: element.email,
                                conEmail: element.conEmail,
                                id: element.id,
                                first_name: element.first_name,
                                last_name: element.last_name,
                                rol: element.rol,
                                password: element.password,
                                conPassword: element.conPassword,
                                enterprise: [6],
                              });
                              setShow(true);
                            }}
                          >
                            <h5>
                              <Icon
                                className="text-ssbazul"
                                name="edit"
                              />
                            </h5>
                          </a>
                        </Row>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Block>
      </Container>
      {selectUser.id ? (
        <ModalUser
          show={show}
          setShow={setShow}
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          roles={roles}
          enterprise={enterprise}
          handleClose={handleClose}
        />
      ) : null}

      {/* <Modal
        isOpen={show}
        toggle={() => setShow(false)}
        size="xl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <ModalHeader toggle={() => setShow(false)}>
            Edit User {selectUser?.nickname}
          </ModalHeader>
          <div className="p-2">
            <div className="tab-content">
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-label" htmlFor="name">
                      Name
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      id="nickname"
                      defaultValue={selectUser?.nickname}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="form-label" htmlFor="name">
                      email
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      id="nickname"
                      defaultValue={selectUser?.email}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-label" htmlFor="name">
                      Name
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      id="nickname"
                      defaultValue={selectUser?.nickname}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="form-label" htmlFor="name">
                      email
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      id="nickname"
                      defaultValue={selectUser?.email}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button>Submit</Button>
        </ModalFooter>
      </Modal> */}
    </Fragment>
  );
};
