import {
  CustomReport,
  ReportSpec,
} from '../Reports/custom-report/types/report-types';
import HttpClient from '../../plasticos/helpers/providers.helper';
import {BACKEND} from '../../consts/backend';

type APIResponse<T = unknown> = {
  error?: T;
  data?: {
    state: boolean;
    id?: string | number;
    current?: Record<string, any> | null;
  };
};

type UpdateReport = {
  name: string;
  spec: ReportSpec;
};

const API_BASE_URL = `${BACKEND}`;

export class CustomReportProviderExport {
  httpClient = HttpClient;

  public static async createCustomReport<T = APIResponse>(
    body: CustomReport
  ): Promise<APIResponse<T>> {
    return new Promise<APIResponse<T>>((resolve) => {
      HttpClient.post(
        API_BASE_URL + '/reports/exportation/custom',
        body
      )
        .then(resolve)
        .catch((error) => ({error}));
    });
  }

  public static async updateCustomReport<T = APIResponse>(
    body: UpdateReport,
    reportId: number
  ): Promise<APIResponse<T>> {
    return new Promise<APIResponse<T>>((resolve) => {
      HttpClient.patch(
        API_BASE_URL + '/reports/exportation/custom/' + reportId,
        body
      )
        .then(resolve)
        .catch((error) => ({error}));
    });
  }

  public static async getAllReportsByUser<T = any>(userRef: string) {
    const response = await HttpClient.get<T>(
      API_BASE_URL + '/reports/exportation/custom/' + userRef
    );
    return response.data;
  }

  public static async getReportById<T = APIResponse>(
    reportId: string
  ) {
    const response = await HttpClient.get<T>(
      API_BASE_URL + '/reports/exportation/custom/by-id/' + reportId
    );
    return response.data;
  }

  public static async deleteReportById<T = APIResponse>(
    reportId: string
  ) {
    const response = await HttpClient.delete<T>(
      API_BASE_URL + '/reports/exportation/custom/' + reportId
    );
    return response.data;
  }
}
