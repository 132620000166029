import React from "react";
import LogoLight2x from "../../images/ssb_white.158c7b19.png";
import LogoDark2x from "../../images/ssb_white.158c7b19.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
      <img className="logo-light logo-img" src={LogoLight2x} alt="logo" height="80" width="97" position="absolute" />
      <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" />
    </Link>
  );
};

export default Logo;
