import {useState, useEffect} from 'react';
import GetFollowupExpo from './getfollowupexpo';

const useGetFollowupExpo = () => {
  const [state, setState] = useState({
    data: [],
  });

  useEffect(() => {
    GetFollowupExpo().then((imgs) => {
      setState({
        data: imgs.data,
      });
    });
  }, []);

  return state;
};

export default useGetFollowupExpo;
