import HttpClient from '../../helpers/providers.helper';
import {BACKEND} from '../../../../../consts/backend';

export class TerminalProvider {
  httpClient = HttpClient;

  async deleteTerminal(id) {
    return await this.httpClient
      .patch(`${BACKEND}/expo/expo-terminal/delete/${id}`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async createTermianl(po) {
    let body = JSON.stringify(po);

    return await this.httpClient
      .post(`${BACKEND}/expo/add/expo-terminal`, body)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async editTerminal(id, po) {
    let body = JSON.stringify(po);
    return await this.httpClient
      .patch(`${BACKEND}/expo/patch/expo-terminal/ ${id}`, body)
      .then((result) => result.data.data)
      .catch((err) => err);
  }

  async getTerminal() {
    return await this.httpClient
      .get(`${BACKEND}/expo-material/expo-terminal`)
      .then((result) => result.data.data)
      .catch((err) => err);
  }
}
