import {useEffect} from 'react';
import {
  Button,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Table,
  Col,
  Row,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import CustomSelect from '../../Exportation/followUp/Components/customselect';
import {getRoles} from './../helpers/getRole.js';

export const ModalUser = ({
  show,
  setShow,
  selectUser,
  setSelectUser,
  roles,
  enterprise,
  handleClose,
}) => {
  useEffect(() => {
    getRoles(selectUser.id).then((response) => {
      setSelectUser((element) => ({
        ...element,
        rol: response[0].id,
      }));
    });
  }, []);

  const onChange = (e, id, index = '', name) => {};

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        handleClose();
        setShow(false);
      }}
      size="xl"
      className="modal-dialog-centered"
    >
      <ModalBody>
        <ModalHeader toggle={() => setShow(false)}>
          Edit User {selectUser?.nickname}
        </ModalHeader>
        <div className="p-2">
          <div className="tab-content">
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    First Name
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="first_name"
                    defaultValue={selectUser?.first_name}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Last Name
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="last_name"
                    defaultValue={selectUser?.last_name}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Enterprise
                  </Label>
                  <CustomSelect
                    id="enterprise"
                    optionsSelect={enterprise}
                    defaultValueSelect={selectUser.enterprise}
                    onChange={onChange}
                    multi={true}
                  />
                </FormGroup>
              </Col>
              {selectUser.rol ? (
                <Col>
                  <FormGroup>
                    <Label className="form-label" htmlFor="name">
                      rol
                    </Label>
                    <CustomSelect
                      optionsSelect={roles}
                      id="rol"
                      defaultValueSelect={selectUser.rol}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Password
                  </Label>
                  <Input
                    className="form-control"
                    type="password"
                    id="password"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Confirm Password
                  </Label>
                  <Input
                    className="form-control"
                    type="password"
                    id="conPassword"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    E-mail
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="email"
                    defaultValue={selectUser.email}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    Confirm E-mail
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="conEmail"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button>Submit</Button>
      </ModalFooter>
    </Modal>
  );
};
