import {Fragment, useState} from 'react';
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import {Block, Icon} from '../../components/Component';
import CustomSelect from '../../Exportation/followUp/Components/customselect';
import {ImageFile} from './ImageFile';

export const CreateEnterprise = ({users}) => {
  const [enterprise, setEnterprise] = useState({
    name: '',
  });

  const onChange = (e, id, index, name) => {};
  return (
    <Fragment>
      <Container>
        <Block>
          <div className="nk-modal-head mb-3">
            <h5>Create Enterprise</h5>
          </div>
          <Row className="gy-3">
            <Col md="12">
              <div className={`accordion-head`}>
                <div className="accordion-item">
                  <div className="accordion-inner">
                    <Row className="mb-auto p-2">
                      <Col md="6">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            Enterprise Name
                          </Label>
                          <Input id="nameUser" type="text" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <Label
                          className="form-label"
                          htmlFor="nameUser"
                        >
                          Image
                        </Label>
                        <ImageFile />
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            className="form-label"
                            htmlFor="nameUser"
                          >
                            Users
                          </Label>
                          <CustomSelect
                            optionsSelect={users}
                            defaultValueSelect={[]}
                            onChange={onChange}
                            multi={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-auto p-2">
                      <Col md="6">
                        <FormGroup>
                          <Button>
                            <Icon name="plus" />
                            Create Enterprise
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Block>
      </Container>
    </Fragment>
  );
};
