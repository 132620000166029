/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="nk-footer nk-footer-fluid bg-lighter">
      <div className="container-xl">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            {" "}
            &copy; 2022 SSB International 
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
             
              </li>
              <li className="nav-item">
               
              </li>
              <li className="nav-item">
              
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
